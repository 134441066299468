import { FC } from 'react';
import { useMedia } from 'react-use';

import { translate } from '@/i18n';
import { iconsButtonMap } from '@/shared/components/buttons/IconButton/IconButton.types.tsx';
import { Tabs } from '@/shared/components/navigation/Tabs';
import { TabCounter } from '@/shared/components/ui/TabCounter';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';

import { TabContentProps } from '@/shared/components/ui/TabContent/TabContent.types';

export const TabContent: FC<TabContentProps> = ({
  category,
  count = 0,
  disabled = false,
  onClick,
  withCounter = false,
}) => {
  const Icon = iconsButtonMap[category];
  const isMobile = useMedia('(max-width: 767px)');

  const CurrentTab = isMobile ? Tabs.Tab : Tabs.ActionsTab;

  const renderLocalizedHeading = () => {
    switch (category) {
      case 'actions':
        return translate('settings.actions');
      case 'archive':
        return translate('actions.archive');
      case 'equipment':
        return translate('actions.equipment');
      case 'reservation':
        return translate('actions.reservation');
      case 'safety':
        return translate('actions.safety');
      case 'system':
        return translate('global.system');
      case 'zone':
        return translate('actions.zone');
      default:
        return translate('actions.all');
    }
  };

  return (
    <CurrentTab
      aria-details={category}
      className={classnames('flex items-center justify-between text-slate-950')}
      disabled={disabled}
      onClick={onClick && onClick}
    >
      <div className="inline-flex items-center gap-x-2">
        <Icon className="h-5 w-5 shrink-0" />

        <Typography className="text-nowrap text-base md:text-sm">
          {renderLocalizedHeading()}
        </Typography>
      </div>

      {!isMobile && withCounter && <TabCounter count={count} />}
    </CurrentTab>
  );
};
