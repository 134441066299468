import { useEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';

import { useIsFetching, useQueryClient } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { ReservationMobileList } from '@/modules/Reports/components/ReservationMobileList';
import { ReservationPopup } from '@/modules/Reservations/components/ReservationPopup';
import { Calender } from '@/modules/Reservations/components/TabPanels/ScheduleTab/CalenderSubTab';
import { renderColumns } from '@/modules/Reservations/components/TabPanels/ScheduleTab/Schedule.utils.tsx';
import { useModifyReservationData } from '@/modules/Reservations/hooks/useModifyReservationData';
import { Button } from '@/shared/components/buttons/Button';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { Loader } from '@/shared/components/ui/Loader';
import { SwitchTabs } from '@/shared/components/ui/SwitchTabs';
import { TopSection } from '@/shared/components/ui/TopSection';
import { useModalHandlerTypes } from '@/shared/hooks/global/useModalHandlerTypes';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity';
import { QUERY_KEY } from '@/shared/utils/constants';
import { isActionAllowed } from '@/shared/utils/roles';

import Refresh from '@/assets/icons/refresh-sm.svg?react';
import { RolesEnum } from '@/shared/types/global/enums';
import { EventData, ReservationAssetType } from '@/shared/types/global/reservations.types.ts';

export const Schedule = () => {
  const hasCalendar = isActionAllowed('calendar_view');

  const [selectedIndex, setSelectedIndex] = useState<string>(hasCalendar ? '1' : '2');
  const selectedEvent = useRef<EventData>();
  const [showPopup, setShowPopup] = useState(false);

  const queryClient = useQueryClient();
  const isMobile = useMedia('(max-width: 767px)');
  const isLoading = useIsFetching({
    queryKey: [QUERY_KEY.RESERVATION],
  });

  const { updatedCalenderData, updatedListData } = useModifyReservationData();
  const {
    reservationApproveHandler,
    reservationDeleteHandler,
    reservationRejectHandler,
    reservationSubmitHandler,
  } = useModalHandlerTypes();
  const { renderItemVisibility } = useRole();

  const isManager = renderItemVisibility([RolesEnum.MANAGER, RolesEnum.ENGINEER]);
  const isWorker = renderItemVisibility([RolesEnum.WORKER]);

  const handleSubmit = (reservation: ReservationAssetType) => reservationSubmitHandler(reservation);
  const handleReject = (reservation: ReservationAssetType) => reservationRejectHandler(reservation);
  const handleApprove = (reservation: ReservationAssetType) =>
    reservationApproveHandler(reservation);
  const handleDelete = (reservation: ReservationAssetType) => reservationDeleteHandler(reservation);

  const selectEvent = (item: ReservationAssetType) => {
    const { assetData, ...rest } = item;

    selectedEvent.current = {
      ...assetData,
      reservations: [rest.conflictData?.conflictingReservations || []],
      selectedEvent: { ...rest },
    };

    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    selectedEvent.current = undefined;
  };

  const handleRefresh = async () => {
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.RESERVATION] });
  };

  useEffect(() => {
    if (isMobile && !isWorker) setSelectedIndex('2');
  }, [isMobile, isWorker]);

  const renderedBody = () => {
    if (isLoading) {
      return (
        <div className="flex h-full content-center items-center">
          <Loader appearance="section" loaderText={translate('confirm.loading')} />
        </div>
      );
    }

    if (selectedIndex === '2') {
      return (
        <>
          {isMobile && (
            <ReservationMobileList
              handleApprove={handleApprove}
              handleDelete={handleDelete}
              handleReject={handleReject}
              handleSubmit={handleSubmit}
              reservations={updatedListData ?? []}
              selectEvent={selectEvent}
            />
          )}

          {!isMobile && (
            <DefaultTable
              className="max-h-[calc(100dvh-300px)]"
              columns={renderColumns(
                handleSubmit,
                handleReject,
                handleApprove,
                handleDelete,
                selectEvent,
                isWorker,
                isManager,
              )}
              contentRowType={'equipment-reservations'}
              data={updatedListData ?? []}
              headerClassName="min-w-[200px]"
              isPaginated={false}
              perPageCount="20"
            />
          )}

          {showPopup && (
            <>
              {selectedEvent.current ? (
                <ReservationPopup closePopup={closePopup} eventData={selectedEvent.current} />
              ) : null}
            </>
          )}
        </>
      );
    }

    return <Calender modifiedReservation={updatedCalenderData ?? []} />;
  };

  return (
    <div className="relative flex h-full flex-col gap-y-2 md:gap-y-4">
      <div className="w-full items-center lg:flex">
        <TopSection
          className="max-w-[80%]"
          scope={['date', 'firm', 'equipment-status', 'subtype']}
          withMaxDate={false}
        />

        <div className="absolute right-0 top-0 !mt-0 flex flex-row gap-x-2">
          <SwitchTabs
            className={`${isWorker ? 'hidden' : '!mt-0'}`}
            firstTabIcon={'calender'}
            firstTabTitle={'reservation.calendar'}
            secondTabIcon={'listSmall'}
            secondTabTitle={'reservation.list'}
            selectedIndex={selectedIndex}
            tabChangeEvent={setSelectedIndex}
          />

          <Button
            className="hidden h-10 gap-x-2 md:inline-flex"
            onClick={() => handleRefresh()}
            variant="outline"
          >
            {translate('reservation.refresh')} <Refresh />
          </Button>

          <Button className="h-10 md:hidden" onClick={() => handleRefresh()} variant="outline">
            <Refresh />
          </Button>
        </div>
      </div>

      {renderedBody()}
    </div>
  );
};
