import { findTagValue } from '@/shared/utils/assets';
import { isEmptyOrIncludes } from '@/shared/utils/is-empty-or-includes.ts';
import { isNotDefinedOrIsEqual } from '@/shared/utils/is-not-defined-or-is-equal.ts';

import { AssetBody, AssetCategory } from '@/shared/types/assets/assets.types.ts';
import { MapFilterType } from '@/shared/types/global/filters.types.ts';
import { GeofencesProps } from '@/shared/types/global/geofences.types.ts';
import { ReadersProps } from '@/shared/types/global/readers.types.ts';

type FilteredDataProps = {
  workers: AssetBody[];
  equipment: AssetBody[];
  geofences: GeofencesProps[];
  readers: ReadersProps[];
  assetResName: null | string;
};

export class SidebarFilterService {
  private readonly assetResourceName: null | string;
  private readonly filters: MapFilterType;
  private readonly searchKey: string;

  constructor(filters: MapFilterType, searchKey: string, assetResourceName: null | string) {
    this.filters = filters;
    this.searchKey = searchKey;
    this.assetResourceName = assetResourceName;
  }

  private filterEquipment(equipment: AssetBody[], assetCategories: AssetCategory[]): AssetBody[] {
    const { equipmentReservedBy, equipmentSubType, equipmentType, firm, group, resName } =
      this.filters;

    if (
      this.searchKey.length === 0 &&
      firm.length === 0 &&
      equipmentType.length === 0 &&
      equipmentSubType.length === 0 &&
      equipmentReservedBy.length === 0 &&
      group.length === 0 &&
      resName.length === 0
    ) {
      return equipment;
    }

    return equipment.filter(equipment => {
      const _firm = equipment?.companyName ?? '';
      const _category = assetCategories.find(
        cat => cat.categoryTreeResName === equipment.categoryTreeResName,
      );
      const _subCategory = _category?.categoryPathList.find(
        sub => sub.categoryPathResName === equipment.categoryPathResName,
      );
      const _reservedBy = findTagValue(equipment, 'reservedBy') ?? '';
      const _group = findTagValue(equipment, 'group') ?? '';

      return (
        isEmptyOrIncludes(firm, _firm) &&
        isEmptyOrIncludes(equipmentType, _category?.categoryTreeName) &&
        isEmptyOrIncludes(equipmentSubType, _subCategory?.categoryPath) &&
        isEmptyOrIncludes(equipmentReservedBy, _reservedBy) &&
        isEmptyOrIncludes(group, _group) &&
        isEmptyOrIncludes(resName, equipment.assetResName)
      );
    });
  }

  private filterGeofences(geofences: GeofencesProps[]): GeofencesProps[] {
    const { zoneFloor, zoneType } = this.filters;

    if (zoneType.length === 0 && zoneFloor.length === 0) return geofences;

    return geofences.filter(geofence => {
      const _type = geofence?.geofenceCategory;
      const _floor = geofence?.floorResName;
      return isEmptyOrIncludes(zoneType, _type) && isEmptyOrIncludes(zoneFloor, _floor);
    });
  }

  private filterWorkers(workers: AssetBody[]): AssetBody[] {
    const { firm, group, resName, workerRole } = this.filters;

    if (
      this.searchKey.length === 0 &&
      firm.length === 0 &&
      workerRole.length === 0 &&
      group.length === 0 &&
      resName.length === 0
    )
      return workers;

    return workers.filter(worker => {
      const _firm = worker?.companyName ?? '';
      const _role = findTagValue(worker, 'role') ?? '';
      const _group = findTagValue(worker, 'group') ?? '';

      return (
        isEmptyOrIncludes(firm, _firm) &&
        isEmptyOrIncludes(workerRole, _role) &&
        isEmptyOrIncludes(group, _group) &&
        isEmptyOrIncludes(resName, worker.assetResName)
      );
    });
  }

  public getFilteredData(
    assetCategories: AssetCategory[],
    workers: AssetBody[],
    equipment: AssetBody[],
    geofences: GeofencesProps[],
    readers: ReadersProps[],
  ): FilteredDataProps {
    const filteredWorkers = this.filterWorkers(workers);
    const filteredEquipment = this.filterEquipment(equipment, assetCategories);
    const filteredGeofences = this.filterGeofences(geofences);

    return {
      assetResName: this.assetResourceName,

      equipment: filteredEquipment.filter(equipment =>
        isEmptyOrIncludes(this.searchKey, equipment.assetName),
      ),

      geofences: filteredGeofences.filter(
        geofence =>
          isEmptyOrIncludes(this.searchKey, geofence.geofenceName) &&
          isNotDefinedOrIsEqual(this.assetResourceName, geofence.geofenceResName),
      ),

      readers: readers.filter(
        reader =>
          isEmptyOrIncludes(this.searchKey, reader.readerName) &&
          isNotDefinedOrIsEqual(this.assetResourceName, reader.readerResName),
      ),

      workers: filteredWorkers.filter(worker =>
        isEmptyOrIncludes(this.searchKey, worker.assetName),
      ),
    };
  }
}
