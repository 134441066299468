import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import { translate } from '@/i18n';
import { localizedSectionHeading } from '@/modules/Actions/utils';
import { Pagination } from '@/shared/components/navigation/Pagination';
import { ActionCard } from '@/shared/components/ui/ActionCard';
import { Loader } from '@/shared/components/ui/Loader';
import { NoData } from '@/shared/components/ui/NoData';
import { Typography } from '@/shared/components/ui/Typography';

import { DisclosureItemProps } from '@/modules/Actions/types/types.ts';

type Props = Pick<DisclosureItemProps, 'items'> & {
  label: string;
  withPagination?: boolean;
  withInfiniteScroll?: {
    hasNextPage: boolean;
    isLoading: boolean;
    onFetchNextPage: () => void;
    overflowScrollElement: HTMLDivElement;
  };
};

export const FullList = ({ items = [], label, withInfiniteScroll, withPagination }: Props) => {
  const [pageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const infiniteTriggerRef = useRef(null);

  const infiniteTriggerIntersection = useIntersection(infiniteTriggerRef, {
    root: withInfiniteScroll?.overflowScrollElement,
    rootMargin: '0px',
    threshold: 1,
  });

  const paginatedItems = useMemo(() => {
    const newItemList = [...items].sort((a, b) => b.timestamp - a.timestamp);

    return withPagination
      ? newItemList.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      : newItemList;
  }, [items, currentPage, pageSize, withPagination]);

  if (items.length <= 0) {
    return <NoData title={translate('actions.noActionsFound')} />;
  }

  return (
    <div className="flex w-full flex-col gap-1.5 overflow-hidden">
      <Typography className="text-[18px] font-medium">{localizedSectionHeading(label)}</Typography>

      <div className="flex w-full flex-col gap-3">
        {paginatedItems?.map(item => (
          <ActionCard
            action={item}
            buttonPlacement={label === 'Archive' ? undefined : 'row'}
            key={item.alertResName}
          />
        ))}
      </div>

      {withPagination && (
        <Pagination
          className="justify-center"
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          totalCount={items.length}
        />
      )}

      {withInfiniteScroll && (
        <InfiniteScrollTrigger
          fullyInView={
            infiniteTriggerIntersection
              ? infiniteTriggerIntersection.intersectionRatio === 1
              : false
          }
          hasNextPage={withInfiniteScroll.hasNextPage}
          isLoading={withInfiniteScroll.isLoading}
          onFullyInView={withInfiniteScroll.onFetchNextPage}
          ref={infiniteTriggerRef}
        />
      )}
    </div>
  );
};

interface InfiniteScrollTriggerProps {
  fullyInView: boolean;
  hasNextPage: boolean;
  isLoading: boolean;
  onFullyInView: () => void;
}

const InfiniteScrollTrigger = forwardRef<HTMLDivElement, InfiniteScrollTriggerProps>(
  ({ fullyInView, hasNextPage, isLoading, onFullyInView }: InfiniteScrollTriggerProps, ref) => {
    useEffect(() => {
      if (fullyInView && hasNextPage && !isLoading) onFullyInView();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullyInView]);

    return (
      <div className="mb-1 py-4" ref={ref}>
        {isLoading && (
          <div className="flex items-center justify-center [&_span]:!size-8 [&_span]:!text-blue-500">
            <Loader appearance="inline" />
          </div>
        )}

        {!hasNextPage && (
          <Typography className="text-center text-sm text-slate-500">
            {translate('actions.noMoreActionsToShow')}
          </Typography>
        )}
      </div>
    );
  },
);
