import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IconButton } from '@/shared/components/buttons/IconButton';
import { Select } from '@/shared/components/form/Select';
import { useGlobalStore } from '@/shared/store/store.ts';
import { URL_PARAMS } from '@/shared/utils/constants.ts';

import Zone from '@/assets/icons/zone-extra-sm.svg?react';

type Props = {
  isLoading: boolean;
};

export const FloorSelector: FC<Props> = ({ isLoading }) => {
  const [, setParameters] = useSearchParams();
  const currentFloor = useGlobalStore(store => store.floor);
  const floorsList = useGlobalStore(store => store.floorsList);
  const setFloor = useGlobalStore(store => store.setFloor);
  const closeActivePopups = useGlobalStore(store => store.closeActivePopups);

  const handleSetFloor = (floorId: string) => {
    const floor = floorsList.find(floor => floor.id === floorId) ?? null;

    setFloor(floor);
    closeActivePopups();
    setParameters(parameters => {
      parameters.delete(URL_PARAMS.GEOFENCE_RES_NAME);
      return parameters;
    });
  };

  return (
    <div className="absolute bottom-4 left-1/2 z-20 inline-flex -translate-x-1/2 gap-x-2">
      <div className="hidden h-[38px] items-center divide-x divide-slate-200 rounded-lg border border-slate-200 bg-white shadow-sm md:inline-flex">
        <IconButton
          className="size-[38px] rotate-180 transform border-0"
          disabled
          icon="arrow-sm"
        />

        <IconButton className="size-[38px] border-0" disabled icon="arrow-sm" />
      </div>

      <Select
        className="min-h-[38px] min-w-[260px] bg-white shadow-sm"
        data={floorsList}
        defaultValue={currentFloor?.id ?? floorsList[0]?.id}
        disabled={floorsList.length <= 0 || isLoading}
        icon={<Zone />}
        onChange={handleSetFloor}
        reversed
        variant="floor-picker"
      />
    </div>
  );
};
