import { DatePicker } from '@/shared/components/form/DatePicker/DatePicker.tsx';
import { getTimestampInSec } from '@/shared/utils/date-fns.ts';

import { Scope } from '@/shared/components/ui/TopSection/TopSection.types.ts';

type DatePickerWithMultiSelectProps = {
  selectedDateRange: string[];
  handleSetSearchParameter: (item: Scope, value: string) => void;
  item: Scope;
  withMaxDate?: boolean;
};

export function DatePickerWithMultiSelect({
  handleSetSearchParameter,
  item,
  selectedDateRange,
  withMaxDate = false,
}: DatePickerWithMultiSelectProps) {
  const startDate = selectedDateRange[0] ? new Date(Number(selectedDateRange[0]) * 1000) : null;
  const endDate = selectedDateRange[1] ? new Date(Number(selectedDateRange[1]) * 1000) : null;

  const handleDateChange = (dates: Date[]) => {
    // eslint-disable-next-line prefer-const
    let [start, end] = dates;

    if (start && end && start.getTime() === end.getTime()) {
      end = new Date(start);
      end.setHours(23, 59, 59, 999);
    } else if (end) {
      end.setHours(23, 59, 59, 999);
    }

    const formattedValue =
      start && end
        ? `${getTimestampInSec(start)},${getTimestampInSec(end)}`
        : start
        ? getTimestampInSec(start).toString()
        : '';

    handleSetSearchParameter(item, formattedValue);
  };

  return (
    <DatePicker
      dateFormat="dd.MM.yyy"
      endDate={endDate}
      icon="calendar"
      key={item.toLowerCase()}
      onChange={dates => handleDateChange(dates as unknown as Date[])}
      placeholderText={startDate && endDate ? undefined : '-'}
      selected={startDate}
      selectsDisabledDaysInRange
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      selectsRange
      startDate={startDate}
      withArrows
      withMaxDate={withMaxDate}
    />
  );
}
