import { ColumnDef } from '@tanstack/react-table';

import { translate } from '@/i18n';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { TableLink } from '@/shared/components/tables/TableLink';
import { Typography } from '@/shared/components/ui/Typography';
import { MISSING_DATA } from '@/shared/utils/constants.ts';

import { RolesEnum } from '@/shared/types/global/enums.ts';
import { TableActions, WorkersDatasource } from '@/shared/types/users/subcontractors.types.ts';

export const renderColumns = (
  action: (type: TableActions, id: string) => void,
  renderItemVisibility: (list: RolesEnum[]) => boolean,
): ColumnDef<WorkersDatasource>[] => {
  const isVisible = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.ENGINEER,
    RolesEnum.SUBCONTRACTOR,
    RolesEnum.KOTO,
  ]);

  const isVisiblePartial = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.ENGINEER,
    RolesEnum.SUBCONTRACTOR,
  ]);

  return [
    {
      accessorKey: 'given_name',
      cell: ({ row }) => (
        <TableLink to={`${row.original.id}`}>{row.original.given_name ?? MISSING_DATA}</TableLink>
      ),
      header: () => translate('global.givenName'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorKey: 'family_name',
      cell: ({ row }) => (
        <Typography as="span" className="max-w-32 cursor-pointer truncate font-bold 2xl:max-w-full">
          {row.original.family_name ?? MISSING_DATA}
        </Typography>
      ),
      header: () => translate('global.familyName'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorKey: 'role',
      cell: ({ row }) => <Typography as="span">{row.original.role ?? MISSING_DATA}</Typography>,
      header: () => translate('global.role'),
      meta: {
        isFiltered: true,
      },
    },
    // {
    //   accessorFn: row => row.currentZone?.enclosingGeofences[0].geofenceName,
    //   accessorKey: 'currentZone',
    //   cell: ({ row }) => {
    //     return (
    //       <Typography as="span">
    //         {isVisible
    //           ? row.original.currentZone?.enclosingGeofences[0].geofenceName ?? MISSING_DATA
    //           : MISSING_DATA}
    //       </Typography>
    //     );
    //   },
    //   header: () => translate('global.currentZone'),
    //   meta: {
    //     isFiltered: true,
    //   },
    // },
    {
      accessorKey: 'tag',
      cell: ({ row }) => {
        return (
          <Typography as="span">
            {isVisible
              ? row.original.attached?.map(tag => tag.tag.tagName).join(',\n') ?? MISSING_DATA
              : MISSING_DATA}
          </Typography>
        );
      },
      header: () => translate('global.tagsAssigned'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorKey: 'unresolved_actions',
      cell: ({ row }) => {
        return (
          <Typography as="span">
            {isVisiblePartial ? row.original.unresolved_actions ?? MISSING_DATA : MISSING_DATA}
          </Typography>
        );
      },
      header: () => translate('global.unresolvedActions'),
    },
    {
      accessorKey: 'actions',
      cell: ({ row }) => (
        <div className="inline-flex gap-x-2.5">
          <IconButton
            className="size-[38px] rounded-md border border-slate-200"
            icon="edit-sm"
            onClick={() => action('edit', row.original.id)}
          />
          <IconButton
            className="size-[38px] rounded-md border border-slate-200 text-red-500"
            icon="delete-sm"
            onClick={() => action('delete', row.original.id)}
          />
        </div>
      ),
      header: '',
    },
  ];
};
