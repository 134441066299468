import { translate } from '@/i18n';

export const localizedSectionHeading = (label: string) => {
  switch (label) {
    case 'Archive':
      return translate('actions.archive');
    case 'Equipment':
    case 'Equipments':
      return translate('actions.equipment');
    case 'Reservation':
    case 'Reservations':
      return translate('actions.reservation');
    case 'Safety':
      return translate('actions.safety');
    case 'System':
      return translate('global.system');
    case 'Zone':
    case 'Zones':
      return translate('actions.zone');
    default:
      return translate('actions.all');
  }
};
