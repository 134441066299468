import { FC, useEffect, useRef } from 'react';
import { useMedia } from 'react-use';

import { subDays } from 'date-fns';

import { translate } from '@/i18n';
import * as Lists from '@/modules/Actions/components';
import { useActionPageDetails } from '@/modules/Actions/hooks/useActionPageDetails.ts';
import { useActionsHook } from '@/shared/api/actions/actions.hook.ts';
import { Fade } from '@/shared/components/animation/Fade';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { SearchInput } from '@/shared/components/form/Input';
import { Label } from '@/shared/components/form/Label';
import { Tabs } from '@/shared/components/navigation/Tabs';
import { Loader } from '@/shared/components/ui/Loader';
import { NoData } from '@/shared/components/ui/NoData';
import { PageWrapper } from '@/shared/components/ui/PageWrapper';
import { TabContent } from '@/shared/components/ui/TabContent';
import { Typography } from '@/shared/components/ui/Typography';
import { useActions } from '@/shared/hooks/actions/useActions.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames';
import { RolesWrapper } from '@/shared/wrappers/Roles.tsx';

const archivedStartTimestamp = subDays(new Date(), 7).getTime();
const archivedEndTimestamp = new Date().getTime();

export const Actions: FC = () => {
  const isTabletAndDesktop = useMedia('(min-width: 768px)');
  const overflowScrollElementRef = useRef<HTMLDivElement>(null);

  const wsAlertAvailable = useGlobalStore(store => store.wsAlertAvailable);
  const setWsAlertAvailable = useGlobalStore(store => store.setWsAlertAvailable);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useActionsHook.getArchivedAlerts(
    archivedStartTimestamp,
    archivedEndTimestamp,
  );

  const {
    archivedAlerts,
    equipmentAlertCount,
    equipmentAlerts,
    hasPriority,
    isLoading,
    reservationAlertCount,
    reservationAlerts,
    safetyAlertCount,
    safetyAlerts,
    systemAlertCount,
    systemAlerts,
    totalCount,
    zoneAlertCount,
    zoneAlerts,
  } = useActions();

  const { getTabIndex, onTabChange } = useActionPageDetails();

  const fetchNextArchivedAlertsPage = () => {
    void fetchNextPage();
  };

  useEffect(() => {
    if (wsAlertAvailable) setWsAlertAvailable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsAlertAvailable]);

  return (
    <Fade>
      <PageWrapper withSidebar={isTabletAndDesktop}>
        <Tabs.Group onChange={onTabChange} selectedIndex={getTabIndex()}>
          <div
            className={classnames(
              'visible relative flex flex-shrink-0 flex-col gap-2 border-slate-200 transition-[width,visibility] duration-300 md:border-r md:px-2 md:py-3',
            )}
          >
            <Typography as="h1" className="pt-2 text-xl font-medium">
              {translate('navbar.actions')}
            </Typography>

            <div className="mb-2 flex items-center gap-x-2">
              <Label>
                <SearchInput disabled />
              </Label>

              <IconButton className="shrink-0" disabled icon="filter" />
            </div>

            <Tabs.List className="flex overflow-x-auto md:flex-col md:gap-2.5">
              <TabContent category="all" count={totalCount} withCounter />

              <RolesWrapper action="system">
                <TabContent category="system" count={systemAlertCount} withCounter />
              </RolesWrapper>

              <RolesWrapper action="safety">
                <TabContent category="safety" count={safetyAlertCount} withCounter />
              </RolesWrapper>

              <RolesWrapper action="equipment">
                <TabContent category="equipment" count={equipmentAlertCount} withCounter />
              </RolesWrapper>

              <RolesWrapper action="zones">
                <TabContent category="zone" count={zoneAlertCount} withCounter />
              </RolesWrapper>

              <RolesWrapper action="reservation">
                <TabContent category="reservation" count={reservationAlertCount} withCounter />
              </RolesWrapper>

              <RolesWrapper action="archive">
                <TabContent category="archive" />
              </RolesWrapper>
            </Tabs.List>
          </div>

          {isLoading ? (
            <div className="flex h-3/4 flex-col items-center justify-center md:h-full">
              <Loader appearance="section" loaderText={translate('confirm.loading')} />
            </div>
          ) : totalCount <= 0 ? (
            <NoData title={translate('actions.noActionsFound')} />
          ) : (
            <div
              className="flex h-full w-full flex-col gap-4 overflow-auto py-5 md:bg-white md:px-3"
              ref={overflowScrollElementRef}
            >
              <Tabs.TabPanels className="h-full p-0">
                <Tabs.TabPanel
                  className={classnames('grid h-full gap-4', {
                    'md:grid-cols-1': !hasPriority,
                    'md:grid-cols-2': hasPriority,
                  })}
                >
                  <Lists.MostImportantList className="md:col-span-2" />

                  <Lists.DisclosureItem
                    count={systemAlertCount}
                    heading="System"
                    items={systemAlerts}
                  />

                  <Lists.DisclosureItem
                    count={safetyAlertCount}
                    heading="Safety"
                    items={safetyAlerts}
                  />

                  <Lists.DisclosureItem
                    count={equipmentAlertCount}
                    heading="Equipment"
                    items={equipmentAlerts}
                  />

                  <Lists.DisclosureItem count={zoneAlertCount} heading="Zone" items={zoneAlerts} />

                  <Lists.DisclosureItem
                    count={reservationAlertCount}
                    heading="Reservation"
                    items={reservationAlerts}
                  />
                </Tabs.TabPanel>

                <RolesWrapper action="system">
                  <Tabs.TabPanel className="h-full">
                    <Lists.FullList items={systemAlerts} label="System" withPagination />
                  </Tabs.TabPanel>
                </RolesWrapper>

                <RolesWrapper action="safety">
                  <Tabs.TabPanel className="h-full">
                    <Lists.FullList items={safetyAlerts} label="Safety" withPagination />
                  </Tabs.TabPanel>
                </RolesWrapper>

                <RolesWrapper action="equipment">
                  <Tabs.TabPanel className="h-full">
                    <Lists.FullList items={equipmentAlerts} label="Equipments" withPagination />
                  </Tabs.TabPanel>
                </RolesWrapper>

                <RolesWrapper action="zones">
                  <Tabs.TabPanel className="h-full">
                    <Lists.FullList items={zoneAlerts} label="Zones" withPagination />
                  </Tabs.TabPanel>
                </RolesWrapper>

                <RolesWrapper action="reservation">
                  <Tabs.TabPanel className="h-full">
                    <Lists.FullList items={reservationAlerts} label="Reservations" withPagination />
                  </Tabs.TabPanel>
                </RolesWrapper>

                <RolesWrapper action="archive">
                  <Tabs.TabPanel className="h-full">
                    {overflowScrollElementRef.current && (
                      <Lists.FullList
                        items={archivedAlerts}
                        label="Archive"
                        withInfiniteScroll={{
                          hasNextPage: hasNextPage,
                          isLoading: isFetchingNextPage,
                          onFetchNextPage: fetchNextArchivedAlertsPage,
                          overflowScrollElement: overflowScrollElementRef.current,
                        }}
                      />
                    )}
                  </Tabs.TabPanel>
                </RolesWrapper>
              </Tabs.TabPanels>
            </div>
          )}
        </Tabs.Group>
      </PageWrapper>
    </Fade>
  );
};
