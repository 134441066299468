import { ColumnDef } from '@tanstack/react-table';

import { translate } from '@/i18n';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { TableLink } from '@/shared/components/tables/TableLink';
import { classnames } from '@/shared/utils/classnames.ts';

import { RolesEnum } from '@/shared/types/global/enums.ts';
import { FirmsDatasource, TableActions } from '@/shared/types/users/subcontractors.types.ts';

export const renderColumns = (
  action: (type: TableActions, id: string) => void,
  renderItemVisibility: (list: RolesEnum[]) => boolean,
): ColumnDef<FirmsDatasource>[] => {
  const isVisible = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.ENGINEER,
    RolesEnum.SUBCONTRACTOR,
    RolesEnum.KOTO,
  ]);

  return [
    {
      accessorKey: 'firm_name',
      cell: ({ row }) => (
        <TableLink
          className={classnames(!isVisible && 'pointer-events-none')}
          to={`${row.original.id}`}
        >
          {row.original.firm_name}
        </TableLink>
      ),
      header: () => translate('global.firms'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorKey: 'total_workers',
      cell: ({ row }) => <div className="capitalize">{row.original.total_workers}</div>,
      header: () => translate('global.totalWorkers'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorKey: 'on_site',
      cell: ({ row }) => <div>{row.original.on_site ?? '-'}</div>,
      header: () => translate('global.onSite'),
      meta: {
        isFiltered: true,
      },
    },
    {
      accessorKey: 'actions',
      cell: ({ row }) => (
        <div className="inline-flex gap-x-2.5">
          <IconButton
            className="size-[38px] rounded-md border-[1px] border-slate-200"
            icon="edit-sm"
            onClick={() => action('edit', row.original.id)}
          />

          <IconButton
            className="size-[38px] rounded-md border-[1px] border-slate-200 text-red-500"
            disabled={true}
            icon="delete-sm"
            onClick={() => action('delete', row.original.id)}
          />
        </div>
      ),
      header: '',
    },
  ];
};
