import { FC } from 'react';

import { translate } from '@/i18n';
import { MobileCard } from '@/shared/components/ui/MobileCard';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { MISSING_DATA } from '@/shared/utils/constants.ts';

import { RolesEnum } from '@/shared/types/global/enums.ts';
import { TableActions, WorkersDatasource } from '@/shared/types/users/subcontractors.types.ts';

type Props = {
  worker: WorkersDatasource;
  action: (type: TableActions, id: string) => void;
};

export const DetailsMobileCard: FC<Props> = ({ action, worker }) => {
  const { renderItemVisibility } = useRole();

  const isVisible = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.ENGINEER,
    RolesEnum.SUBCONTRACTOR,
    RolesEnum.KOTO,
  ]);

  return (
    <MobileCard
      action={{
        label: translate('map.edit'),
        onClick: () => action('edit', worker.id),
      }}
      details={[
        {
          label: translate('global.givenName'),
          value: worker?.given_name ?? MISSING_DATA,
        },
        {
          label: translate('global.familyName'),
          value: worker?.family_name ?? MISSING_DATA,
        },
        {
          label: translate('global.role'),
          value: worker?.role ?? MISSING_DATA,
        },
        {
          label: translate('global.currentZone'),
          value: isVisible ? worker?.current_zone ?? MISSING_DATA : MISSING_DATA,
        },
        {
          label: translate('global.unresolvedActions'),
          value: isVisible ? worker?.unresolved_actions ?? MISSING_DATA : MISSING_DATA,
        },
      ]}
      link={isVisible ? `${worker.id}` : ''}
      status={worker.status}
      statusColor={worker?.status === 'active' ? 'green' : 'gray'}
    />
  );
};
