import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//import { type Value } from '@number-flow/react';

import { translate } from '@/i18n';
import { DetailsMobileList } from '@/modules/Subcontractors/components/common/DetailsMobileList';
import { useFirmDetailSearch } from '@/modules/Subcontractors/hooks/useFirmDetailSearch.ts';
import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { renderColumns } from '@/modules/Subcontractors/utils/Details.utils';
import { useAssetHooks } from '@/shared/api/assets/assets.hooks.ts';
import { Button } from '@/shared/components/buttons/Button';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { AssetControlPanel } from '@/shared/components/ui/AssetControlPanel';
import { StatisticCard } from '@/shared/components/ui/Card';
import { TopSection } from '@/shared/components/ui/TopSection';
import { useAssetStatus } from '@/shared/hooks/assets/useAssetStatus';
import { useToggleAssetsStatusInBatch } from '@/shared/hooks/assets/useToggleAssetsStatusInBatch';
import { useToggleModal } from '@/shared/hooks/global/useToggleModal.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { useGlobalStore } from '@/shared/store/store.ts';
import { URL_PARAMS } from '@/shared/utils/constants';

import { PopupTypeEnum, RolesEnum, RoutesEnum } from '@/shared/types/global/enums.ts';
import { TableActions } from '@/shared/types/users/subcontractors.types.ts';

export const Details: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const batchedWorkers = useGlobalStore(state => state.batchedWorkers);
  const setBatchedWorkers = useGlobalStore(state => state.setBatchedWorkers);
  const setMapFilter = useGlobalStore(state => state.setMapFilter);

  const workerRole = searchParams.get(URL_PARAMS.ROLE);
  const workerZone = searchParams.get(URL_PARAMS.CURENT_ZONE);
  const workerTagAttached = searchParams.get(URL_PARAMS.TAG_ATTACHED);

  const { isFetching: isWorkersLoading, refetch } = useAssetHooks.getAllWorkers();

  const { handler } = useToggleModal();
  const { /*activeFirmAlt,*/ people } = usePeopleAsset();
  const { isShowInactive, toggleShowInactive } = useAssetStatus();
  const { isPending, mutate } = useToggleAssetsStatusInBatch();
  const { renderItemVisibility } = useRole();

  const { filteredWorkers } = useFirmDetailSearch({
    workerRole,
    workers: people,
    workerTagAttached,
    workerZone,
  });

  //const peopleOnSite = activeFirmAlt?.on_site as Value;

  const isCardVisible = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.ENGINEER,
    RolesEnum.SUBCONTRACTOR,
    RolesEnum.KOTO,
  ]);

  const handleTableAction = (action: TableActions, workerId: string) => {
    if (action === 'edit') {
      return handler(PopupTypeEnum.SUBCONTRACTORS_WORKER_EDIT, workerId);
    }

    return handler(PopupTypeEnum.CONFIRM_DELETE_WORKER, workerId);
  };

  const handleCancelChanges = () => setBatchedWorkers([]);
  const handleSaveChanges = async () => mutate(batchedWorkers);

  const handleShowWorkersOnMap = () => {
    setMapFilter({ resName: filteredWorkers.map(worker => worker.id) });
    navigate(RoutesEnum.HOME);
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="inline-flex w-full flex-wrap items-center justify-between gap-y-2">
        <TopSection scope={['role', 'attached']} />

        <div className="flex gap-x-3">
          <AssetControlPanel
            checked={isShowInactive}
            disabled={isWorkersLoading}
            onChange={toggleShowInactive}
            onRefresh={refetch}
            onShowOnMap={handleShowWorkersOnMap}
            scopes={['toggle', 'refresh']}
          />

          {!!batchedWorkers?.length && (
            <>
              <Button
                className="inline-flex shrink-0 items-center justify-center gap-x-2"
                isLoading={isPending}
                onClick={handleCancelChanges}
                variant="outline"
              >
                {translate('filters.cancel')}
              </Button>

              <Button
                className="inline-flex shrink-0 items-center justify-center gap-x-2 text-white"
                isLoading={isPending}
                onClick={handleSaveChanges}
                variant="solid"
              >
                {translate('settings.save')}
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="inline-flex w-full flex-col items-center gap-3 md:flex-row">
        {isCardVisible && (
          <>
            <StatisticCard
              className="w-full"
              icon="eye"
              isLoading={isWorkersLoading}
              subtitle={people.length ?? 0}
              titleId="global.totalWorkers"
            />
            {/*
            <StatisticCard
              className="w-full"
              icon="eye"
              isLoading={isWorkersLoading}
              subtitle={peopleOnSite ?? 0}
              titleId="global.workersOnSite"
            />
            */}
          </>
        )}
      </div>

      <DetailsMobileList
        action={handleTableAction}
        className="md:hidden"
        isLoading={isWorkersLoading}
        workers={filteredWorkers}
      />

      <div className="hidden gap-y-5 md:grid">
        <DefaultTable
          className="break-anywhere"
          columns={renderColumns(handleTableAction, renderItemVisibility)}
          contentRowType="worker"
          data={filteredWorkers}
          isLoading={isWorkersLoading}
          isPaginated
          withToggle={isShowInactive}
        />
      </div>
    </div>
  );
};
