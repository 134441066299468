import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { translate } from '@/i18n';
import { NotFound } from '@/modules/Reports/components/NotFound';
import { ActionGroup, ActionsCategory } from '@/modules/Settings/components/common/ActionsGroup';
import { useActions as useSettingsActions } from '@/modules/Settings/hooks/useActions.ts';
import { Fade } from '@/shared/components/animation/Fade';
import { Button } from '@/shared/components/buttons/Button';
import { Toggle } from '@/shared/components/form/Toggle';
import { Skeleton } from '@/shared/components/ui/Skeleton';
import { Typography } from '@/shared/components/ui/Typography';
import { useActions } from '@/shared/hooks/actions/useActions.ts';

import { ActionCategoryEnum } from '@/shared/types/actions/actions.types.ts';

export const Actions: FC = () => {
  const {
    equipmentAlerts,
    isLoading,
    reservationAlerts,
    safetyAlerts,
    systemAlerts,
    totalCount,
    zoneAlerts,
  } = useActions();

  const { form, handleFormSubmit, isCreating, isUpdating } = useSettingsActions();

  return (
    <Fade>
      <form
        autoComplete="off"
        className="flex flex-col gap-y-4 p-1"
        onSubmit={form.handleSubmit(handleFormSubmit)}
      >
        <div className="inline-flex items-center gap-x-3">
          <Controller
            control={form.control}
            defaultValue={true}
            name="enabled"
            render={({ field: { onChange, value } }) => (
              <Toggle checked={value ?? false} onChange={onChange} />
            )}
          />

          <Typography as="span" className="text-sm font-medium">
            {translate('settings.receiveNotificationsFrom')}
          </Typography>
        </div>

        {isLoading ? (
          <Skeleton count={1} variant="table" />
        ) : totalCount <= 0 ? (
          <NotFound secondary />
        ) : (
          <ActionGroup>
            <ActionsCategory
              alerts={systemAlerts}
              control={form.control}
              getValues={form.getValues}
              title={ActionCategoryEnum.SYSTEM}
            />

            <ActionsCategory
              alerts={safetyAlerts}
              control={form.control}
              getValues={form.getValues}
              title={ActionCategoryEnum.SAFETY}
            />

            <ActionsCategory
              alerts={equipmentAlerts}
              control={form.control}
              getValues={form.getValues}
              title={ActionCategoryEnum.EQUIPMENT}
            />

            <ActionsCategory
              alerts={zoneAlerts}
              control={form.control}
              getValues={form.getValues}
              title={ActionCategoryEnum.ZONE}
            />

            <ActionsCategory
              alerts={reservationAlerts}
              control={form.control}
              getValues={form.getValues}
              title={ActionCategoryEnum.RESERVATION}
            />
          </ActionGroup>
        )}

        <div className="inline-flex h-[38px] w-fit gap-x-2">
          <Button
            aria-label="actions-settings"
            className="h-full min-w-[90px] font-medium"
            isLoading={isCreating || isUpdating}
            type="submit"
            variant="solid"
          >
            {translate('settings.save')}
          </Button>

          <Button
            className="h-full font-medium text-red-500"
            onClick={form.reset}
            type="reset"
            variant="outline"
          >
            {translate('settings.resetToDefault')}
          </Button>
        </div>
      </form>
    </Fade>
  );
};
