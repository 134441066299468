import { useTableChartBody } from '@/shared/hooks/table/useTableChartBody.tsx';
import { useTableDefaultBody } from '@/shared/hooks/table/useTableDefaultBody.tsx';
import { useTableEquipmentBody } from '@/shared/hooks/table/useTableEquipmentBody.tsx';
import { useTableEquipmentReservationsBody } from '@/shared/hooks/table/useTableEquipmentReservationsBody.tsx';
import { useTableFirmBody } from '@/shared/hooks/table/useTableFirmBody.tsx';

import { useTableReservationsBody } from './useTableReservationsBody';
import { useTableWorkerBody } from './useTableWorkerBody';

import {
  ContentRowTypeProps,
  ItemsFunction,
  TableBodyItemsProps,
} from '@/shared/components/tables/DefaultTable/DefaultTable.types.ts';

export const useTableBodyItems = <T>(
  contentRowType: ContentRowTypeProps,
  tableBodyProps: TableBodyItemsProps<T>,
  isFirstRowReduced?: boolean,
) => {
  let items: ItemsFunction<T>;

  switch (contentRowType) {
    case 'chart':
      items = useTableChartBody(tableBodyProps).items;
      break;

    case 'equipment':
      items = useTableEquipmentBody({ ...tableBodyProps, isFirstRowReduced }).items;
      break;

    case 'equipment-reservations':
      items = useTableEquipmentReservationsBody(tableBodyProps).items;
      break;

    case 'firm':
      items = useTableFirmBody(tableBodyProps).items;
      break;

    case 'reservations':
      items = useTableReservationsBody(tableBodyProps).items;
      break;

    case 'worker':
      items = useTableWorkerBody(tableBodyProps).items;
      break;

    default:
      items = useTableDefaultBody(tableBodyProps).items;
      break;
  }

  return { items };
};
