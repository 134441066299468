import { FC, useMemo } from 'react';

import { Disclosure } from '@headlessui/react';

import { translate } from '@/i18n';
import { useActionPageDetails } from '@/modules/Actions/hooks/useActionPageDetails.ts';
import { localizedSectionHeading } from '@/modules/Actions/utils';
import { Button } from '@/shared/components/buttons/Button';
import { ActionCard } from '@/shared/components/ui/ActionCard';
import { TabCounter } from '@/shared/components/ui/TabCounter';
import { classnames } from '@/shared/utils/classnames.ts';

import VecArrow from '@/assets/icons/vec-arrow.svg?react';
import { DisclosureItemProps } from '@/modules/Actions/types/types.ts';

export const DisclosureItem: FC<DisclosureItemProps> = ({ count, heading, items = [] }) => {
  const { goToCurrentTab } = useActionPageDetails();

  const previewData = useMemo(() => {
    return [...items].slice(0, 5).sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
  }, [items]);

  if (items.length === 0) return null;

  return (
    <Disclosure aria-details={`${heading.toLowerCase()} alerts`} as="div">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classnames(
              'flex h-[40px] w-full items-center justify-between rounded-xl border-[1px] border-slate-200 bg-white px-4 text-start text-lg font-medium text-slate-950 shadow-sm',
              { 'rounded-b-none rounded-t-xl': open },
            )}
          >
            <div className="inline-flex items-center justify-center gap-3">
              {localizedSectionHeading(heading)}
              <TabCounter count={count} />
            </div>

            <VecArrow className={classnames({ 'rotate-180': open })} />
          </Disclosure.Button>

          <Disclosure.Panel className="flex w-full flex-col gap-0.5 overflow-hidden rounded-b-xl border-b border-l border-r">
            <div className="flex w-full flex-col gap-3 p-2 pt-3">
              {previewData?.map(item => (
                <ActionCard action={item} buttonPlacement="col" key={item.alertResName} />
              ))}
            </div>

            <Button
              aria-label="view all"
              className="bg-slate-100 px-4 py-[13px] text-sm font-semibold text-slate-800"
              onClick={() => goToCurrentTab(heading)}
              variant="custom"
            >
              {translate('global.viewAll')}
            </Button>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
