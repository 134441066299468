import { useNavigate, useSearchParams } from 'react-router-dom';

import { URL_PARAMS } from '@/shared/utils/constants';

import { RoutesEnum } from '@/shared/types/global/enums.ts';
import { SidebarParameters } from '@/shared/types/global/parameters.types.ts';

export const useActionPageDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const updateSearchParams = (key: string, value: string) => {
    setSearchParams(searchParams => {
      searchParams.set(key, value);
      return searchParams;
    });
  };

  const getTabIndex = () => {
    if (searchParams.get(URL_PARAMS.ACTIONS) === SidebarParameters.SYSTEM) {
      return 1;
    }

    if (searchParams.get(URL_PARAMS.ACTIONS) === SidebarParameters.SAFETY) {
      return 2;
    }

    if (searchParams.get(URL_PARAMS.ACTIONS) === SidebarParameters.EQUIPMENT) {
      return 3;
    }

    if (searchParams.get(URL_PARAMS.ACTIONS) === SidebarParameters.ZONE) {
      return 4;
    }

    if (searchParams.get(URL_PARAMS.ACTIONS) === SidebarParameters.RESERVATION) {
      return 5;
    }

    if (searchParams.get(URL_PARAMS.ACTIONS) === SidebarParameters.ARCHIVE) {
      return 6;
    }

    return 0;
  };

  const onTabChange = (tab: number) => {
    switch (tab) {
      case 0:
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.ALL);
        break;
      case 1:
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.SYSTEM);
        break;
      case 2:
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.SAFETY);
        break;
      case 3:
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.EQUIPMENT);
        break;
      case 4:
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.ZONE);
        break;
      case 5:
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.RESERVATION);
        break;
      case 6:
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.ARCHIVE);
        break;
    }
  };

  const goToCurrentTab = (heading: string) => {
    switch (heading) {
      case 'Archive':
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.ARCHIVE);
        break;
      case 'Equipment':
        navigate({
          pathname: RoutesEnum.ACTIONS,
          search: `?${URL_PARAMS.ACTIONS}=${SidebarParameters.EQUIPMENT}`,
        });
        break;
      case 'Reservation':
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.RESERVATION);
        break;
      case 'Safety':
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.SAFETY);
        break;
      case 'System':
        navigate({
          pathname: RoutesEnum.ACTIONS,
          search: `?${URL_PARAMS.ACTIONS}=${SidebarParameters.SYSTEM}`,
        });
        break;
      case 'Zone':
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.ZONE);
        break;
      default:
        updateSearchParams(URL_PARAMS.ACTIONS, SidebarParameters.ALL);
        break;
    }
  };

  return {
    getTabIndex,
    goToCurrentTab,
    onTabChange,
    searchParams,
    updateSearchParams,
  };
};
