import { FC } from 'react';
import { FieldValues } from 'react-hook-form';
import { useMedia } from 'react-use';

import { translate } from '@/i18n';
import { workflowStates } from '@/modules/Reservations/utils/helpers';
import { Button } from '@/shared/components/buttons/Button';
import { ButtonsGroup } from '@/shared/components/buttons/ButtonsGroup';
import { DatePicker } from '@/shared/components/form/DatePicker';
import { DatePickerWithMultiSelect } from '@/shared/components/form/DatePicker/DatePickerWithMultiSelect.tsx';
import { ExtendedAssetsSelect } from '@/shared/components/form/ExtendedAssetsSelect';
import { FilterSelect } from '@/shared/components/form/FilterSelect';
import { SearchInput } from '@/shared/components/form/Input';
import { Select } from '@/shared/components/form/Select';
import { renderScopeItem } from '@/shared/components/ui/TopSection/TopSection.utils.tsx';
import { useTopSection } from '@/shared/hooks/global/useTopSection.ts';
import { classnames } from '@/shared/utils/classnames';

import { TopSectionProps } from '@/shared/components/ui/TopSection/TopSection.types';
import { MapFilterPropEnum } from '@/shared/types/global/filters.types.ts';

export const TopSection: FC<TopSectionProps> = ({
  assetsInitialType,
  assetsWithTags = false,
  className,
  disableFromTo = false,
  extraButtonsGroup = false,
  isSearchBarHidden = false,
  scope,
  selectedBold = false,
  withMaxDate = false,
  ...props
}) => {
  const {
    handleClearParams,
    handleSearchFieldChange,
    handleSetSearchParameter,
    modifiedAssets,
    modifiedAvailability,
    modifiedFirms,
    modifiedGeofences,
    modifiedRoles,
    modifiedTagsAttached,
    modifiedZones,
    onValid,
    params,
    searchParameters,
  } = useTopSection(scope ?? [], false, extraButtonsGroup);
  const isMobile = useMedia('(max-width: 768px)');

  const renderScopeList = () => {
    const methodsList: FieldValues = {
      modifiedAssets,
      modifiedAvailability,
      modifiedFirms,
      modifiedGeofences,
      modifiedRoles,
      modifiedTagsAttached,
      modifiedZones,
    };

    return scope?.map(item => {
      const props = {
        defaultValue: params.getValues(item) ?? undefined,
        onChange: (value: string) => handleSetSearchParameter(item, value),
        variant: 'default' as 'color-picker' | 'default',
        ...renderScopeItem(item, methodsList),
        label: item === 'zone' ? 'global.currentZone' : 'assets.' + item,
        selectedBold,
      };

      if (item === 'multi-zone') {
        const selectedZones = params.getValues(item) ?? searchParameters.get(item) ?? '';
        const modifiedSelectedZones = selectedZones.length > 0 ? selectedZones.split(',') : '';

        return (
          <FilterSelect
            asSelect
            assetsWithTags={assetsWithTags}
            className="min-h-[38px] w-[260px] bg-white"
            key={item.toLowerCase()}
            longText={false}
            name={MapFilterPropEnum.ZONE_ITEM}
            onChange={value => handleSetSearchParameter(item, value.join(','))}
            value={(modifiedSelectedZones as string[]) ?? []}
          />
        );
      }

      if (item === 'multi-asset') {
        const selectedAssets = params.getValues(item) ?? searchParameters.get(item) ?? '';
        const modifiedSelectedAssets = selectedAssets.length > 0 ? selectedAssets.split(',') : '';

        return (
          <ExtendedAssetsSelect
            asSelect
            assetsWithTags={assetsWithTags}
            className="min-h-[38px] w-[260px] bg-white"
            initialType={assetsInitialType}
            key={item.toLowerCase()}
            onChange={value => handleSetSearchParameter(item, value.join(','))}
            value={(modifiedSelectedAssets as string[]) ?? []}
          />
        );
      }

      if (item === 'reservation') {
        const selectedAssets = params.getValues(item) ?? searchParameters.get(item) ?? '';
        const modifiedSelectedAssets = selectedAssets.length > 0 ? selectedAssets.split(',') : '';

        return (
          <FilterSelect
            asSelect
            className="min-h-[38px] w-[200px] bg-white"
            key={item.toLowerCase()}
            name={MapFilterPropEnum.RES_NAME}
            onChange={value => handleSetSearchParameter(item, value.join(','))}
            value={(modifiedSelectedAssets as string[]) ?? []}
          />
        );
      }

      if (item === 'workflowState') {
        return (
          <Select
            className="min-h-[38px] min-w-[250px] bg-white"
            key={item.toLowerCase()}
            {...props}
            data={workflowStates}
          />
        );
      }

      if (item === 'date') {
        return (
          <DatePicker
            dateFormat="dd.MM.yyy"
            icon="calendar"
            key={item.toLowerCase()}
            onChange={value => handleSetSearchParameter(item, value?.toISOString() as string)}
            selected={params.getValues(item)}
            withArrows
            withMaxDate={withMaxDate}
          />
        );
      }

      if (item === 'multi-date') {
        const selectedDateRange = params.getValues(item)?.split(',') ?? [];

        return (
          <DatePickerWithMultiSelect
            handleSetSearchParameter={handleSetSearchParameter}
            item={item}
            key={item.toLowerCase()}
            selectedDateRange={selectedDateRange}
            withMaxDate={withMaxDate}
          />
        );
      }

      return (
        <Select
          className="min-h-[38px] min-w-[200px] bg-white"
          key={item.toLowerCase()}
          {...props}
        />
      );
    });
  };

  return (
    <div className={classnames('inline-flex flex-wrap gap-2 md:gap-6', className)} {...props}>
      <form
        className={classnames('inline-flex w-full flex-wrap gap-2 md:gap-6')}
        onSubmit={params.handleSubmit(onValid)}
      >
        <div className="inline-flex flex-wrap gap-2 md:flex-row">
          {!isSearchBarHidden && (
            <div className="relative">
              <SearchInput
                className={classnames('w-[200px]', {
                  'text-sm': isMobile,
                })}
                onChange={value => handleSearchFieldChange(value as string)}
              />
            </div>
          )}

          <div className="top-section flex flex-wrap gap-2 xl:flex-nowrap">{renderScopeList()}</div>
        </div>

        {scope && scope.length > 0 && (
          <div className="inline-flex gap-2">
            <Button type="submit" variant="solid">
              {translate('global.submit')}
            </Button>

            <Button
              className="text-red-500"
              onClick={handleClearParams}
              type="reset"
              variant="ghost"
            >
              {translate('global.clear')}
            </Button>
          </div>
        )}

        {extraButtonsGroup && (
          <ButtonsGroup
            className="ml-auto"
            items={[
              {
                icon: 'eye-sm',
                id: 'time',
                name: 'calendar.time',
              },
              {
                icon: 'filter',
                id: 'entries',
                name: 'global.entries',
              },
            ]}
          />
        )}
      </form>
    </div>
  );
};
