import { actionRoleAccessMap } from '@/shared/api/actions/actions.const.ts';
import { AssetsAPINamespace as AssetsAPINamespace2 } from '@/shared/api/assets';
import { AssetsAPINamespace } from '@/shared/api/assets/assets.requests.ts';
import { GeofencesAPINamespace } from '@/shared/api/geofences/geofences.request.ts';
import { ReadersAPINamespace } from '@/shared/api/readers/readers.requests.ts';
import { ReservationsAPINamespace } from '@/shared/api/reservations/reservations.requests.ts';
import { SitesAPINamespace } from '@/shared/api/sites/sites.requests.ts';

import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';
import {
  ActionCategoryEnum,
  ActionTitleEnum,
  AlertParsedMeta,
  AlertProps,
  MetricProps,
} from '@/shared/types/actions/actions.types.ts';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { RolesEnum } from '@/shared/types/global/enums.ts';
import { Reservation } from '@/shared/types/global/reservations.types.ts';

export const postProcessAlerts = async (
  alerts: AlertProps[],
  accountResName: string,
  siteResName: string,
  userRole: null | RolesEnum,
) => {
  try {
    const relationIds = new Set<string>();
    const relations = {} as { [k: string]: unknown };
    const fetchRelationActions = [] as Promise<void>[];

    const { equipmentCategory, workerCategory } = await getAssetCategories(
      accountResName,
      siteResName,
    );

    // OPTIMIZATION: Filter by access control list before loop to reduce API calls
    alerts = alerts.filter(action => actionIsAccessibleByRole(action, userRole));

    for (const alert of alerts) {
      const {
        assetAResName,
        assetBResName,
        assetResName,
        geofenceResName,
        readerResName,
        reservationResName,
        tagResName,
      } = getActionResourceNames(alert);

      if (assetResName && !relationIds.has(assetResName)) {
        relationIds.add(assetResName);

        fetchRelationActions.push(
          AssetsAPINamespace.getOne({
            accountResName: accountResName,
            assetResName: assetResName,
            siteResName: siteResName,
          }).then(asset => {
            relations[assetResName] = asset;
          }),
        );
      }

      if (assetAResName && !relationIds.has(assetAResName)) {
        relationIds.add(assetAResName);

        fetchRelationActions.push(
          AssetsAPINamespace.getOne({
            accountResName: accountResName,
            assetResName: assetAResName,
            siteResName: siteResName,
          }).then(asset => {
            relations[assetAResName] = asset;
          }),
        );
      }

      if (assetBResName && !relationIds.has(assetBResName)) {
        relationIds.add(assetBResName);

        fetchRelationActions.push(
          AssetsAPINamespace.getOne({
            accountResName: accountResName,
            assetResName: assetBResName,
            siteResName: siteResName,
          }).then(asset => {
            relations[assetBResName] = asset;
          }),
        );
      }

      if (tagResName && !relationIds.has(tagResName)) {
        relationIds.add(tagResName);

        fetchRelationActions.push(
          SitesAPINamespace.getTag({
            accountResName: accountResName,
            siteResName: siteResName,
            tagResName: tagResName,
          }).then(tag => {
            relations[tagResName] = tag;
          }),
        );
      }

      if (readerResName && !relationIds.has(readerResName)) {
        relationIds.add(readerResName);

        fetchRelationActions.push(
          ReadersAPINamespace.getOne({
            accountResourceName: accountResName,
            readerResourceName: readerResName,
            siteResourceName: siteResName,
          }).then(reader => {
            relations[readerResName] = reader;
          }),
        );
      }

      if (geofenceResName && !relationIds.has(geofenceResName)) {
        relationIds.add(geofenceResName);

        fetchRelationActions.push(
          GeofencesAPINamespace.getOne({
            accountResName: accountResName,
            geofenceResName: geofenceResName,
            siteResName: siteResName,
          }).then(geofence => {
            relations[geofenceResName] = geofence;
          }),
        );
      }

      if (reservationResName && !relationIds.has(reservationResName)) {
        relationIds.add(reservationResName);

        fetchRelationActions.push(
          ReservationsAPINamespace.getOne({
            accountResName: accountResName,
            reservationResName: reservationResName,
            siteResName: siteResName,
          })
            .then(reservation => {
              relations[reservationResName] = reservation;
              return AssetsAPINamespace2.getZPSUserDetails(accountResName, reservation.user.userId);
            })
            .then(user => {
              if (relations[reservationResName]) {
                // noinspection UnnecessaryLocalVariableJS
                const userFullName = `${user.firstName} ${user.lastName}`;
                (relations[reservationResName] as Reservation).user.userName = userFullName;
              }
            }),
        );
      }
    }

    await Promise.allSettled(fetchRelationActions);

    for (const alert of alerts) {
      const {
        assetAResName,
        assetBResName,
        assetResName,
        geofenceResName,
        readerResName,
        reservationResName,
        tagResName,
      } = getActionResourceNames(alert);

      alert.parsedMeta = parseActionMetadata(alert.metadata);

      if (assetResName) alert.asset = relations[assetResName] as never;
      if (tagResName) alert.tag = relations[tagResName] as never;
      if (readerResName) alert.reader = relations[readerResName] as never;
      if (geofenceResName) alert.geofence = relations[geofenceResName] as never;
      if (reservationResName) alert.reservation = relations[reservationResName] as never;

      if (assetAResName) {
        const asset = relations[assetAResName] as AssetBody;

        if (asset?.categoryTreeResName === equipmentCategory?.categoryTreeResName) {
          alert.proximityEquipmentAsset = asset;
        } else if (asset?.categoryTreeResName === workerCategory?.categoryTreeResName) {
          alert.proximityPersonAsset = asset;
        }
      }

      if (assetBResName) {
        const asset = relations[assetBResName] as AssetBody;

        if (asset?.categoryTreeResName === equipmentCategory?.categoryTreeResName) {
          alert.proximityEquipmentAsset = asset;
        } else if (asset?.categoryTreeResName === workerCategory?.categoryTreeResName) {
          alert.proximityPersonAsset = asset;
        }
      }
    }

    return alerts.filter(actionIsValid);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

function getActionResourceNames(action: AlertProps | MetricProps) {
  if (actionIsAlert(action)) {
    const alert = action as AlertProps;
    const meta = parseActionMetadata(alert.metadata);

    const assetId = meta.assetResName ?? alert.assetResName ?? '';
    const deviceId = meta.deviceResName ?? alert.deviceResName ?? '';
    const geofenceId = meta.geofenceResName ?? '';
    const reservationId = meta.reservationResName ?? '';
    const assetAId = meta.assetAResName ?? '';
    const assetBId = meta.assetBResName ?? '';

    const assetResName = assetId.startsWith('asset') ? assetId : null;
    const tagResName = deviceId.startsWith('tag') ? deviceId : null;
    const readerResName = deviceId.startsWith('readr') ? deviceId : null;
    // prettier-ignore
    const geofenceResName = deviceId.startsWith('geofnc')
      ? deviceId
      : geofenceId.startsWith('geofnc')
        ? geofenceId
        : null;
    const reservationResName = reservationId.startsWith('reservation') ? reservationId : '';
    const assetAResName = assetAId.startsWith('asset') ? assetAId : null;
    const assetBResName = assetBId.startsWith('asset') ? assetBId : null;

    return {
      assetAResName: assetAResName,
      assetBResName: assetBResName,
      assetResName: assetResName,
      geofenceResName: geofenceResName,
      readerResName: readerResName,
      reservationResName: reservationResName,
      tagResName: tagResName,
    };
  } else {
    return {
      assetResName: null,
      geofenceResName: null,
      readerResName: null,
      reservationResName: null,
      tagResName: null,
    };
  }
}

function parseActionMetadata(metadata?: string): AlertParsedMeta {
  try {
    const meta = JSON.parse(metadata ?? '');

    return {
      assetAResName: returnIfNotNone(meta.assetAResName),
      assetBResName: returnIfNotNone(meta.assetBResName),
      assetResName: returnIfNotNone(meta.assetResName),
      deviceResName: returnIfNotNone(meta.deviceResName),
      geofenceResName: returnIfNotNone(meta.geofenceResName),
      proximityZone: returnIfNotNone(meta.proximityZone),
      reservationResName: returnIfNotNone(meta.reservationResName),
    };
  } catch {
    return {};
  }
}

function returnIfNotNone(s?: string) {
  return s && !s.toLowerCase().startsWith('none') ? s : undefined;
}

async function getAssetCategories(accountResName: string, siteResName: string) {
  const cats = await AssetsAPINamespace.getAllCategoryTress(accountResName, siteResName);
  const equipmentCategory = cats.find(c => c.categoryTreeName.includes(AssetTypeEnum.Equipment));
  const workerCategory = cats.find(c => c.categoryTreeName.includes(AssetTypeEnum.Worker));
  return { equipmentCategory, workerCategory };
}

function actionIsValid(action: AlertProps | MetricProps) {
  if (actionIsAlert(action)) {
    const alert = action as AlertProps;
    const hasValidCategory = Object.values(ActionCategoryEnum).includes(alert.alertCategory);

    return (
      (!!alert.asset ||
        !!alert.reader ||
        !!alert.tag ||
        !!alert.geofence ||
        !!alert.reservation ||
        !!alert.proximityPersonAsset ||
        !!alert.proximityEquipmentAsset) &&
      hasValidCategory
    );
  } else return null;
}

function actionIsAccessibleByRole(action: AlertProps | MetricProps, userRole: null | RolesEnum) {
  if (!userRole) return false;

  if (actionIsAlert(action)) {
    const alert = action as AlertProps;
    return actionRoleAccessMap[alert.alertName as ActionTitleEnum]?.[userRole] ?? false;
  } else return false;
}

function actionIsAlert(action: AlertProps | MetricProps) {
  return (
    Object.prototype.hasOwnProperty.call(action, 'alertResName') ||
    Object.prototype.hasOwnProperty.call(action, 'alertName')
  );
}
