import { Fragment, useRef, useState } from 'react';

import { useIsFetching } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { ReservationPopup } from '@/modules/Reservations/components/ReservationPopup';
import {
  Calender_Hours,
  Calender_Hours_List,
  Calender_Minutes_List,
  Calender_Starting_Hour,
} from '@/modules/Reservations/components/TabPanels/ScheduleTab/CalenderSubTab/calender.config';
import { Button } from '@/shared/components/buttons/Button';
import { Loader } from '@/shared/components/ui/Loader';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity';
import { QUERY_KEY, RESERVATIONS } from '@/shared/utils/constants';
import { getTime } from '@/shared/utils/date-fns';

import { RolesEnum } from '@/shared/types/global/enums';
import {
  AssetReservation2D,
  EventData,
  ReservationType,
} from '@/shared/types/global/reservations.types.ts';

const total_columns = Calender_Hours * 4;

export const Calender = (props: { modifiedReservation: AssetReservation2D[] }) => {
  const [showPopup, setShowPopup] = useState(false);
  const selectedEvent = useRef<EventData>();
  const { renderItemVisibility } = useRole();
  const isLoading = useIsFetching({
    queryKey: [QUERY_KEY.RESERVATION],
  });

  const modifiedReservation = props.modifiedReservation;

  const findTimings = (epoch: string) => {
    return getTime(Number(epoch));
  };
  const isManager = renderItemVisibility([RolesEnum.MANAGER, RolesEnum.ENGINEER]);
  const isWorker = renderItemVisibility([RolesEnum.WORKER]);

  const requestedClasses = 'h-[110px] border-blue-600 bg-blue-100 hover:bg-blue-50';
  const draftClasses = 'bg-slate-300 border-slate-800 hover:bg-slate-100 h-[110px]';
  const pendingClasses = 'bg-slate-400 border-slate-800 hover:bg-slate-200 h-[110px]';
  const approvedClasses = 'bg-green-100 border-green-800 hover:bg-green-50 h-[110px]';
  const rejectedClasses = 'bg-red-100 border-red-500 hover:bg-red-50 h-[110px]';
  const conflictedClasses = 'bg-amber-100 border-amber-500 hover:bg-amber-50 h-[75px]';

  const selectEvent = (item: ReservationType, index: number) => {
    selectedEvent.current = { ...modifiedReservation[index], selectedEvent: item };
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    selectedEvent.current = undefined;
  };

  const getStartTimeSlot = (item: ReservationType) => {
    const epochTimest = getTime(Number(item.reservationPeriod.startTime.epochTime)).split(':');

    const hours = epochTimest[0];
    const minutes = epochTimest[1];

    let startSlot =
      Number(hours) > Calender_Starting_Hour ? (Number(hours) - Calender_Starting_Hour) * 4 + 2 : 2;

    if (Number(minutes) < 15) {
      startSlot += 0;
    } else if (Number(minutes) < 30) {
      startSlot += 1;
    } else if (Number(minutes) < 45) {
      startSlot += 2;
    } else {
      startSlot += 3;
    }

    return startSlot;
  };

  const extractNested = (item: ReservationType, nIndex: number) => {
    if (item.workflowContext.currentStateName == RESERVATIONS.requestedSub) {
      return isWorker ? pendingClasses : requestedClasses;
    }
    if (item.workflowContext.currentStateName == RESERVATIONS.draftSub) {
      return draftClasses;
    }
    if (item.workflowContext.currentStateName == RESERVATIONS.approvedSub) {
      return isManager ? requestedClasses : pendingClasses;
    }
    if (
      item.workflowContext.currentStateName == RESERVATIONS.rejectedSub ||
      item.workflowContext.currentStateName == RESERVATIONS.rejectedMgr
    ) {
      return rejectedClasses;
    }
    if (item.workflowContext.currentStateName == RESERVATIONS.conflictedSub) {
      return isWorker ? pendingClasses : `${conflictedClasses} relative mt-[${nIndex * 85}px]`;
    }
    if (item.workflowContext.currentStateName == RESERVATIONS.conflictedMgr) {
      return isManager ? `${conflictedClasses} relative mt-[${nIndex * 85}px]` : pendingClasses;
    }
    if (item.workflowContext.currentStateName == RESERVATIONS.approvedMgr) {
      return approvedClasses;
    }
  };

  const getEndTimeSlot = (item: ReservationType) => {
    const epochTimest = getTime(Number(item.reservationPeriod.endTime.epochTime)).split(':');

    const hours = epochTimest[0];
    const minutes = epochTimest[1];

    let endSlot =
      Number(hours) > Calender_Starting_Hour ? (Number(hours) - Calender_Starting_Hour) * 4 + 2 : 2;

    if (Number(minutes) < 15) {
      endSlot += 0;
    } else if (Number(minutes) < 30) {
      endSlot += 1;
    } else if (Number(minutes) < 45) {
      endSlot += 2;
    } else {
      endSlot += 3;
    }

    return endSlot;
  };

  if (isLoading) {
    return (
      <div className="flex h-full content-center items-center">
        <Loader appearance="section" loaderText={translate('confirm.loading')} />
      </div>
    );
  }

  const checkClass = (inIndex: number) => {
    if (inIndex % 4 == 0) {
      return 'border-l border-l-black';
    } else if (inIndex + 1 == total_columns) {
      return 'border-x border-r-black border-l-grey';
    } else return 'border-l';
  };

  const checkReservationType = (item: ReservationType) => {
    if (item.workflowContext.currentStateName == RESERVATIONS.requestedSub) {
      return isWorker ? (
        <span className="rounded bg-slate-800 px-2 py-1 text-xs text-white">
          {translate('reservation.pending')}
        </span>
      ) : (
        <span className="rounded bg-blue-600 px-2 py-1 text-xs text-white">
          {translate('reservation.requested')}
        </span>
      );
    }
    if (item.workflowContext.currentStateName == RESERVATIONS.conflictedSub) {
      return isWorker ? (
        <span className="rounded bg-slate-800 px-2 py-1 text-xs text-white">
          {translate('reservation.pending')}
        </span>
      ) : (
        <span className="rounded bg-amber-600 px-2 py-1 text-xs text-white">
          {translate('reservation.conflict')}
        </span>
      );
    }
    if (item.workflowContext.currentStateName == RESERVATIONS.approvedSub) {
      return isManager ? (
        <span className="rounded bg-blue-600 px-2 py-1 text-xs text-white">
          {translate('reservation.requested')}
        </span>
      ) : (
        <span className="rounded bg-slate-800 px-2 py-1 text-xs text-white">
          {translate('reservation.pending')}
        </span>
      );
    }
    if (
      item.workflowContext.currentStateName == RESERVATIONS.rejectedSub ||
      item.workflowContext.currentStateName == RESERVATIONS.rejectedMgr
    ) {
      return (
        <span className="rounded bg-red-600 px-2 py-1 text-xs text-white">
          {translate('reservation.rejected')}
        </span>
      );
    }
    if (item.workflowContext.currentStateName == RESERVATIONS.draftSub) {
      return (
        <span className="rounded bg-slate-600 px-2 py-1 text-xs text-white">
          {translate('reservation.draft')}
        </span>
      );
    }
    if (item.workflowContext.currentStateName == RESERVATIONS.approvedMgr) {
      return (
        <span className="rounded bg-green-800 px-2 py-1 text-xs text-white">
          {translate('reservation.approved')}
        </span>
      );
    }
    if (item.workflowContext.currentStateName == RESERVATIONS.conflictedMgr) {
      return isManager ? (
        <span className="rounded bg-amber-600 px-2 py-1 text-xs text-white">
          {translate('reservation.conflict')}
        </span>
      ) : (
        <span className="rounded bg-slate-800 px-2 py-1 text-xs text-white">
          {translate('reservation.pending')}
        </span>
      );
    }
  };

  return (
    <>
      <div className="mainContainer min-w-[1000px] divide-y">
        <div className="inline-flex w-full border-r-black">
          <div className={`sticky top-0 min-w-[230px] `}>
            <span className="text-xs uppercase text-slate-500">
              {translate('global.equipment')}
            </span>
          </div>

          <div className={`inline-flex w-full justify-between`}>
            {Calender_Hours_List.map(temporary => {
              return (
                <div className="sticky top-0" key={`hoursLoop${temporary}`}>
                  <span className="text-xs text-slate-500">{`${temporary}`}</span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="relative max-h-[calc(100vh-320px)] overflow-y-scroll">
          <div className="overflow-clip shadow-xl dark:bg-slate-800">
            <div
              className={`grid grid-cols-[repeat(33,1fr)] overflow-hidden border-b border-r-black`}
            >
              {modifiedReservation?.map((dt, index: number) => {
                return (
                  <Fragment key={`res${dt.assetResName}`}>
                    <div
                      className={`sticky top-0 z-10 col-start-[1] min-h-[120px] w-[230px] truncate border-l border-t bg-white py-2`}
                    >
                      <div className="ml-[10px]">
                        <div className="mb-2 truncate text-xs text-slate-500">{`${dt.subType}`}</div>

                        <div className="mb-2 truncate text-sm text-slate-950">{dt.assetName}</div>

                        {dt.inUse ? (
                          <span className="rounded-md border border-green-600 p-2 text-xs text-green-600">
                            {translate('reservation.inUse')}
                          </span>
                        ) : (
                          <span className="rounded-md border border-blue-600 p-2 text-xs text-blue-600">
                            {translate('reservation.idle')}
                          </span>
                        )}
                      </div>
                    </div>

                    {Calender_Minutes_List.map((_, inIndex) => {
                      return (
                        <div
                          className={`border-t bg-white ${checkClass(inIndex)}`}
                          key={`columns${index}_${inIndex}`}
                          style={{
                            gridColumnEnd: inIndex + 2,
                            gridColumnStart: inIndex + 13,
                            gridRow: index + 1,
                          }}
                        />
                      );
                    })}

                    {dt.reservations?.map((reservation: ReservationType[]) => {
                      return reservation.map((item: ReservationType, vIndex: number) => {
                        return (
                          <Button
                            className={`m-1 cursor-pointer row-start-[${index + 1}] ${extractNested(
                              item,
                              index,
                            )} flex items-center truncate rounded-sm border p-1 pl-[5px]`}
                            key={`keyex${item.reservationResName}`}
                            onClick={() => selectEvent(item, index)}
                            style={{
                              gridColumnEnd: getEndTimeSlot(item),
                              gridColumnStart: getStartTimeSlot(item),
                              gridRow: index + 1,
                              marginTop: vIndex * 125 + 5,
                            }}
                            variant="custom"
                          >
                            <div className="truncate">
                              {checkReservationType(item)}

                              <div className="text-s truncate text-slate-950">
                                {item.user.orgName}
                              </div>

                              <div className="truncate text-xs text-slate-500">{`${findTimings(
                                String(item.reservationPeriod.startTime.epochTime),
                              )} - ${findTimings(
                                String(item.reservationPeriod.endTime.epochTime),
                              )}`}</div>
                            </div>
                          </Button>
                        );
                      });
                    })}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {showPopup ? (
        <>
          {selectedEvent.current ? (
            <ReservationPopup closePopup={closePopup} eventData={selectedEvent.current} />
          ) : null}
        </>
      ) : null}
    </>
  );
};
