import { FC, Fragment, useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { Listbox, Transition } from '@headlessui/react';

import { translate } from '@/i18n';
import { Typography } from '@/shared/components/ui/Typography';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames';

import ChevronUpDownIcon from '@/assets/icons/select-arrow.svg?react';
import { SelectDataProps, SelectProps } from '@/shared/components/form/Select/Select.types';

export const Select: FC<SelectProps> = props => {
  const {
    className = '',
    data = [],
    defaultValue,
    disabled = false,
    emptyStateMessage = '',
    forLanguage = false,
    hasError = false,
    icon,
    isDisabled = false,
    label,
    longText = false,
    onChange,
    reversed = false,
    selectedBold = false,
    uppercased = false,
    variant = 'default',
  } = props;

  const [selected, setSelected] = useState<null | SelectDataProps | undefined>();

  const currentLanguage = useGlobalStore(state => state.currentLanguage);
  const floor = useGlobalStore(state => state.floor);

  const isMobile = useMedia('(max-width: 768px)');
  const isDeepMobile = useMedia('(max-width: 475px)');

  useEffect(() => {
    setSelected(
      forLanguage
        ? data.find(item => item?.id === currentLanguage)
        : data.find(item => item?.id === defaultValue) ?? null,
    );
  }, [currentLanguage, data, defaultValue, forLanguage]);

  const isDefaultVariant = variant === 'default';
  const isColorPickerVariant = variant === 'color-picker';
  const isFloorPickerVariant = variant === 'floor-picker';

  const handleSelectChange = (value: SelectDataProps) => {
    setSelected(value);
    onChange(value?.id);
  };

  const getCurrentSelectTitle = () => {
    if (!selected && label) {
      return translate(`${label}`);
    }

    if (isDisabled) {
      return data[0]?.title ?? data[0]?.name;
    }

    if (forLanguage) {
      return data.find(item => item?.id === currentLanguage)?.title;
    }

    if (!floor && isFloorPickerVariant) {
      return translate('messages.noFloorId');
    }

    if (isColorPickerVariant) return null;

    return selected?.title ?? selected?.name ?? data[0]?.name;
  };

  const renderSortedOptions = () => {
    return data
      .sort((a, b) => {
        if (typeof a.name === 'string' && typeof b.name === 'string')
          return a.name.localeCompare(b.name);
        return 0;
      })
      .sort((a, b) => {
        if (a.assetName && b.assetName) return a.assetName.localeCompare(b.assetName);
        return 0;
      });
  };

  return (
    <Listbox onChange={handleSelectChange} value={selected ?? null}>
      {({ open }) => (
        <div
          className={classnames('relative', {
            'w-full': isDeepMobile,
          })}
        >
          <Listbox.Button
            aria-label="drop-down-select"
            className={classnames(
              'defaultInputStyle inline-flex min-h-10 items-center justify-between gap-x-3',
              className,
              { 'pointer-events-none select-none opacity-50': disabled },
              {
                '!border-rose-500 focus:border-rose-300 focus:outline-none focus:ring focus:ring-rose-200':
                  hasError,
              },
              {
                'w-full': isColorPickerVariant,
              },
            )}
          >
            <div
              className="inline-flex items-center gap-x-1 text-slate-500 first-letter:capitalize"
              data-selected={selected?.name}
            >
              {selected?.color && (
                <span
                  className={`mx-1 h-5 w-5 flex-shrink-0 rounded-[4px] ${selected?.color ?? ''}`}
                  style={{ backgroundColor: selected?.color }}
                />
              )}

              <Typography
                className={classnames('max-w-48 text-left', {
                  'break-anywhere': longText,
                  'font-bold': selected && selectedBold,
                  'pl-2 text-sm': isMobile,
                  truncate: !longText,
                  uppercase: uppercased,
                })}
              >
                {getCurrentSelectTitle()}
              </Typography>

              {icon && <div className="mx-2 flex-shrink-0 text-slate-500">{icon}</div>}
            </div>

            <ChevronUpDownIcon aria-hidden="true" className="h-4.5 w-4.5 text-gray-400" />
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={open}
          >
            <Listbox.Options
              className={classnames(
                'absolute z-[53] mt-1 w-full overflow-auto text-ellipsis rounded-xl bg-white p-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm md:max-h-64',
                {
                  'bottom-[125%]': reversed,
                  'right-0 flex w-[138px] flex-wrap gap-[10px] p-3': isColorPickerVariant,
                },
              )}
              static
            >
              {data.length <= 0
                ? translate(emptyStateMessage) ?? translate('assets.noData')
                : renderSortedOptions()?.map(item =>
                    isDefaultVariant || isFloorPickerVariant ? (
                      <Listbox.Option
                        aria-label={`drop-down-${item.id}-option`}
                        className={({ active }) =>
                          classnames(
                            'relative cursor-pointer p-2 text-start text-slate-800 transition-colors',
                            active && 'rounded-md bg-slate-100',
                          )
                        }
                        key={item?.id ?? item?.name ?? item.assetName}
                        value={item}
                      >
                        {({ selected }) => (
                          <div
                            className={classnames(
                              'inline-flex items-center gap-x-1 first-letter:capitalize',
                              selected && 'text-blue-600',
                            )}
                            data-select-item={item?.name ?? item?.assetName}
                          >
                            <Typography
                              className={classnames('max-w-[250px] text-left', {
                                'break-anywhere': longText,
                                'pl-2 text-sm': isMobile,
                                truncate: !longText,
                                uppercase: uppercased,
                              })}
                            >
                              {item?.color && (
                                <span
                                  className={`inline-block h-[14px] w-[14px] ${item?.color} mr-2 rounded-[5px]`}
                                >
                                  <Typography as="span" className="sr-only">
                                    {item?.name ?? item?.assetName}
                                  </Typography>
                                </span>
                              )}

                              {item?.name ?? item?.assetName}
                            </Typography>
                          </div>
                        )}
                      </Listbox.Option>
                    ) : (
                      <Listbox.Option
                        aria-label={`drop-down-${item.id}-option`}
                        className={({ active }) =>
                          classnames(
                            'relative cursor-pointer text-start text-slate-800 transition-colors',
                            active && 'rounded-md bg-slate-100',
                          )
                        }
                        key={item?.id ?? item?.name ?? item?.assetName}
                        value={item}
                      >
                        {({ selected }) => (
                          <span
                            className={classnames(
                              'block h-5 w-5 rounded-md',
                              selected ? 'ring-2 ring-blue-500 ring-offset-1' : '',
                            )}
                            style={{ backgroundColor: item?.color }}
                          />
                        )}
                      </Listbox.Option>
                    ),
                  )}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
