import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { translate } from '@/i18n';
import { Fade } from '@/shared/components/animation/Fade';
import { GoBackLink } from '@/shared/components/navigation/GoBackLink';
import { Loader } from '@/shared/components/ui/Loader';
import { PageWrapper } from '@/shared/components/ui/PageWrapper';
import { ProseContent } from '@/shared/components/ui/ProseContent';
import { SiteLogo } from '@/shared/components/ui/SiteLogo';
import { useTermsOfUseContent } from '@/shared/hooks/prismic/useTermsOfUseContent.ts';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const TermsOfUse: FC = () => {
  const location = useLocation();
  const { document, field, state } = useTermsOfUseContent();

  return (
    <Fade className="w-full" triggerOnce>
      <PageWrapper className="px-0">
        <div className="container">
          <div className="relative mb-10 flex flex-col gap-y-10">
            <GoBackLink
              className="m-0 text-sm md:absolute md:left-0 md:top-1/2 md:-translate-y-1/2 [&_p]:text-base"
              to={location.state?.from ?? RoutesEnum.LOGIN}
            >
              {translate('global.back')}
            </GoBackLink>

            <SiteLogo className="mx-auto fill-slate-100" color="blue" />
          </div>

          {state !== 'loading' && !document && <Loader appearance="section" fullHeight />}

          {state === 'loaded' && (
            <div className="relative flex flex-col items-center">
              {document && <ProseContent className="max-w-[1536px] text-start" field={field} />}
            </div>
          )}
        </div>
      </PageWrapper>
    </Fade>
  );
};
