import { ColumnDef } from '@tanstack/react-table';

import { translate } from '@/i18n';
import { renderBadgeColor, renderBadgeTitle } from '@/modules/Subcontractors/utils/helpers.tsx';
import { Button } from '@/shared/components/buttons/Button';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { classnames } from '@/shared/utils/classnames';
import { RESERVATIONS } from '@/shared/utils/constants';
import { formatTimestamp } from '@/shared/utils/date-fns';

import { ReservationAssetType } from '@/shared/types/global/reservations.types.ts';

const shouldHideEditButton = (status: string, isWorker: boolean, isManager: boolean) => {
  return (
    (!isWorker && status === RESERVATIONS.conflictedSub) ||
    (isManager && status === RESERVATIONS.conflictedMgr) ||
    status === RESERVATIONS.approvedMgr
  );
};

export const renderColumns = (
  handleSubmit: (responseId: ReservationAssetType) => void,
  handleReject: (responseId: ReservationAssetType) => void,
  handleApprove: (responseId: ReservationAssetType) => void,
  handleDelete: (responseId: ReservationAssetType) => void,
  selectEvent: (temporaryData: ReservationAssetType) => void,
  isWorker: boolean,
  isManager: boolean,
): ColumnDef<ReservationAssetType>[] => [
  {
    accessorKey: 'workflowContext.currentStateName',
    cell: ({ row }) => (
      <div
        className={classnames(
          'w-fit rounded-md border-[1px]  p-[6px] px-2.5 text-xs capitalize text-white',
          renderBadgeColor(row.original.workflowContext.currentStateName, isWorker, isManager),
        )}
      >
        {renderBadgeTitle(row.original.workflowContext.currentStateName, isWorker, isManager)}
      </div>
    ),
    header: () => translate('global.reservation'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'assetData.assetName',
    cell: ({ row }) => (
      <div className="my-[20px] ml-[10px]">
        <div className="mb-[5px] text-xs text-slate-500">{`${row.original.assetData?.subType}`}</div>

        <div className="mb-2 max-w-44 truncate text-sm text-slate-950">
          {row.original.assetData?.assetName}
        </div>

        {row.original.assetData?.inUse ? (
          <span className="rounded-md border border-green-600 p-2 text-xs text-green-600">
            {translate('reservation.inUse')}
          </span>
        ) : (
          <span className="rounded-md border border-blue-600 p-2 text-xs text-blue-600">
            {translate('reservation.idle')}
          </span>
        )}
      </div>
    ),
    header: () => translate('global.equipment'),
    meta: {
      isFiltered: true,
    },
  },
  // {
  //   header: () => translate('global.date'),
  //   accessorKey: 'dateR',
  //   cell: ({ row }) => (
  //     <div>{formatTimestamp(Number(row.original.lastUpdateTime?.epochTime), 'dd/MM/yyyy')}</div>
  //   ),
  // },
  {
    accessorKey: 'reservationPeriod.startTime.epochTime',
    cell: ({ row }) => (
      <div className="flex flex-col gap-y-4">
        {formatTimestamp(
          Number(row.original.reservationPeriod.startTime.epochTime),
          'dd/MM/yyyy HH:mm',
        )}
        {row?.original?.conflictData?.conflictingReservations?.map(item => (
          <div key={item?.reservationResName}>
            {formatTimestamp(
              Number(item.reservationPeriod.startTime.epochTime),
              'dd/MM/yyyy HH:mm',
            )}
          </div>
        ))}
      </div>
    ),
    header: () => translate('reservation.startTime'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'reservationPeriod.endTime.epochTime',
    cell: ({ row }) => (
      <div className="flex flex-col gap-y-4">
        {formatTimestamp(
          Number(row.original.reservationPeriod.endTime.epochTime),
          'dd/MM/yyyy HH:mm',
        )}
        {row?.original?.conflictData?.conflictingReservations?.map(item => (
          <div key={item?.reservationResName}>
            {formatTimestamp(Number(item.reservationPeriod.endTime.epochTime), 'dd/MM/yyyy HH:mm')}
          </div>
        ))}
      </div>
    ),
    header: () => translate('reservation.endTime'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'user.orgName',
    cell: ({ row }) => (
      <div className="flex flex-col gap-y-4">
        {row.original.user.orgName}

        {row?.original?.conflictData?.conflictingReservations?.map(item => (
          <div key={item?.reservationResName}>{item.user.orgName}</div>
        ))}
      </div>
    ),
    header: () => translate('reservation.firm'),
    meta: {
      isFiltered: true,
    },
  },
  {
    accessorKey: 'equipment2',
    cell: ({ row }) => (
      <div className="flex pr-[10px]">
        {!shouldHideEditButton(
          row.original.workflowContext.currentStateName,
          isWorker,
          isManager,
        ) && (
          <IconButton
            className="mr-3 h-[38px] w-[38px] rounded-md border-[1px] border-slate-900"
            icon="edit"
            onClick={() => selectEvent(row.original)}
          />
        )}

        {row.original.workflowContext.currentStateName == RESERVATIONS.draftSub ? (
          <Button
            className="mr-3 inline-flex items-center justify-center gap-x-2"
            onClick={() => handleSubmit(row.original)}
            variant="solid"
          >
            {translate('global.submit')}
          </Button>
        ) : null}

        {(!isWorker &&
          row.original.workflowContext.currentStateName == RESERVATIONS.requestedSub) ||
        (isManager && row.original.workflowContext.currentStateName == RESERVATIONS.approvedSub) ? (
          <Button
            className="mr-3 inline-flex items-center justify-center gap-x-2 whitespace-nowrap h-[38px]"
            onClick={() => handleApprove(row.original)}
            variant="solid"
          >
            {translate('reservation.approve')}
          </Button>
        ) : null}

        {(!isWorker &&
          row.original.workflowContext.currentStateName == RESERVATIONS.requestedSub) ||
        (isManager && row.original.workflowContext.currentStateName == RESERVATIONS.approvedSub) ? (
          <Button
            className="mr-3 inline-flex items-center justify-center gap-x-2 text-red-500 whitespace-nowrap h-[38px]"
            onClick={() => handleReject(row.original)}
            variant="outline"
          >
            {translate('reservation.reject')}
          </Button>
        ) : null}

        {row.original.workflowContext.currentStateName == RESERVATIONS.draftSub ||
        (!isWorker && row.original.workflowContext.currentStateName == RESERVATIONS.approvedSub) ||
        (isWorker && row.original.workflowContext.currentStateName !== RESERVATIONS.approvedMgr) ? (
          <Button
            className="mr-3 inline-flex items-center justify-center gap-x-2 text-red-500 whitespace-nowrap h-[38px]"
            onClick={() => handleDelete(row.original)}
            variant="outline"
          >
            {translate('reservation.delete')}
          </Button>
        ) : null}

        {(!isWorker &&
          row.original.workflowContext.currentStateName == RESERVATIONS.conflictedSub) ||
        (isManager &&
          row.original.workflowContext.currentStateName == RESERVATIONS.conflictedMgr) ? (
          <Button
            className="mr-3 inline-flex items-center justify-center gap-x-2"
            onClick={() => selectEvent(row.original)}
            variant="solid"
          >
            {translate('reservation.review')}
          </Button>
        ) : null}
      </div>
    ),
    header: '',
  },
];
