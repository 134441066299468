import { useGlobalStore } from '@/shared/store/store.ts';
import { MAX_COUNTER } from '@/shared/utils/constants.ts';

import { ActionCategoryEnum, AlertProps } from '@/shared/types/actions/actions.types.ts';

export const useActions = () => {
  const activeAlerts = useGlobalStore(store => store.alertsData);
  const archivedAlerts = useGlobalStore(store => store.archivedAlertsData);
  const metrics = useGlobalStore(store => store.metricsData);
  const isLoadingActions = useGlobalStore(
    store => store.isAlertsDataLoading || store.isMetricsDataLoading,
  );

  const systemAlerts = getCategoryAlerts(activeAlerts, ActionCategoryEnum.SYSTEM);
  const safetyAlerts = getCategoryAlerts(activeAlerts, ActionCategoryEnum.SAFETY);
  const equipmentAlerts = getCategoryAlerts(activeAlerts, ActionCategoryEnum.EQUIPMENT);
  const zoneAlerts = getCategoryAlerts(activeAlerts, ActionCategoryEnum.ZONE);
  const reservationAlerts = getCategoryAlerts(activeAlerts, ActionCategoryEnum.RESERVATION);

  const totalCount = activeAlerts.length + metrics.length;
  const badgeCount = totalCount <= MAX_COUNTER ? totalCount.toString() : `${MAX_COUNTER}+`;

  return {
    activeAlerts: activeAlerts,
    archivedAlerts: archivedAlerts,
    badgeCount: badgeCount,
    equipmentAlertCount: equipmentAlerts.length,
    equipmentAlerts: equipmentAlerts,
    hasPriority: activeAlerts.length > 0,
    isLoading: isLoadingActions,
    reservationAlertCount: reservationAlerts.length,
    reservationAlerts: reservationAlerts,
    safetyAlertCount: safetyAlerts.length,
    safetyAlerts: safetyAlerts,
    systemAlertCount: systemAlerts.length,
    systemAlerts: systemAlerts,
    totalCount: totalCount,
    zoneAlertCount: zoneAlerts.length,
    zoneAlerts: zoneAlerts,
  };
};

function getCategoryAlerts(alerts: AlertProps[], category: ActionCategoryEnum) {
  return alerts.filter(alert => alert.alertCategory === category);
}
