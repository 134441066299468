import type { ReactNode } from 'react';

import { flexRender, Row } from '@tanstack/react-table';

import { TableData } from '@/shared/components/tables/TableData';
import { TableRow } from '@/shared/components/tables/TableRow';
import { classnames } from '@/shared/utils/classnames.ts';

import { UseTableBodyProps } from '@/shared/components/tables/DefaultTable/DefaultTable.types.ts';

export function useTableEquipmentReservationsBody<T>({
  contentRowType,
  extendedRow,
  rowClassName,
  table,
}: UseTableBodyProps<T>) {
  const items = <K extends Record<string, string>>(
    data?: K,
    customRow?: (row: Row<T>) => ReactNode,
  ) => {
    const tableRows = table.getRowModel().rows;
    return tableRows.map(row => {
      const isDeactivated = Object.values(row.original as Record<string, string>).includes(
        'deactivated',
      );

      if (customRow) return customRow(row);

      if (contentRowType === 'equipment-reservations')
        return (
          <TableRow
            className={classnames(isDeactivated && 'bg-slate-50', !data && 'pl-4', rowClassName)}
            data-index={row.index}
            key={row.id}
            ref={node => node}
          >
            {row.getVisibleCells().map(cell => {
              return (
                <TableData
                  className={classnames(
                    extendedRow && 'h-[64px]',
                    !cell.column.columnDef.header &&
                      'min-w-[120px] max-w-[120px] last:min-w-[250px]',
                  )}
                  data-index={row.index}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableData>
              );
            })}
          </TableRow>
        );
    });
  };

  return { items };
}
