import { useRef, useState } from 'react';

import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table';

import { Fade } from '@/shared/components/animation/Fade';
import { NoTableData } from '@/shared/components/tables/NoTableData/NoTableData.tsx';
import { Pagination } from '@/shared/components/tables/Pagination';
import { TableHead } from '@/shared/components/tables/TableHead';
import { Skeleton } from '@/shared/components/ui/Skeleton';
import { useTableBodyItems } from '@/shared/hooks/table/useTableBodyItems.ts';
import { classnames } from '@/shared/utils/classnames';
import { PAGINATION } from '@/shared/utils/constants';

import { DefaultTableProps } from '@/shared/components/tables/DefaultTable/DefaultTable.types';

export const DefaultTable = <T,>({
  accordedChildren,
  cellClassName,
  className = 'max-h-[calc(100dvh-455px)]',
  columns,
  contentRowType = 'default',
  customPageSize,
  customRow,
  data,
  debugTable = false,
  extendedRow = false,
  headerClassName,
  isBodyHeightLimited = true,
  isChart = false,
  isFirstRowReduced = false,
  isLoading,
  isPaginated = false,
  pagesCount = 1,
  perPageCount,
  rowClassName,
  withToggle = false,
  ...props
}: DefaultTableProps<T>) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(customPageSize ?? PAGINATION.PAGE_SIZE);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const start = (pageIndex - 1) * pageSize;
  const end = start + pageSize;

  const caseInsensitiveSort: <T extends RowData>(
    rowA: { getValue: (columnId: string) => T },
    rowB: { getValue: (columnId: string) => T },
    columnId: string,
  ) => number = (rowA, rowB, columnId) => {
    const isString = typeof rowA.getValue(columnId) === 'string';
    const valueA = isString
      ? (rowA.getValue(columnId) as string)?.toLowerCase()
      : rowA.getValue(columnId) ?? '';
    const valueB = isString
      ? (rowB.getValue(columnId) as string)?.toLowerCase()
      : rowB.getValue(columnId) ?? '';
    // Do not sort if values are equal

    if (valueA === valueB) return 0;

    return valueA > valueB ? 1 : -1;
  };

  const table = useReactTable({
    columns: columns.map(column => ({ ...column, sortingFn: caseInsensitiveSort })),
    data: data?.slice(start, end),
    debugTable,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(data?.length / pageSize),
    state: { pagination: { pageIndex, pageSize } },
  });

  const tableBodyProps = {
    contentRowType,
    extendedRow,
    rowClassName,
    table,
    withToggle,
  };

  const { items } = useTableBodyItems(contentRowType, tableBodyProps, isFirstRowReduced);

  const renderBody = () => {
    if (data?.length === 0) return <NoTableData />;

    if (accordedChildren) {
      return accordedChildren(data => items(data, customRow));
    }

    return items(undefined, customRow);
  };

  if (isLoading) return <Skeleton count={table.getAllColumns().length} variant="table" />;

  return (
    <Fade triggerOnce>
      <div
        className="relative w-full overflow-auto rounded-lg border border-slate-200 bg-white"
        ref={tableContainerRef}
      >
        <table
          className={classnames('w-full border-collapse border-spacing-0', className)}
          {...props}
        >
          {!isChart && (
            <TableHead
              cellClassName={cellClassName}
              headerClassName={headerClassName}
              isFirstRowReduced={isFirstRowReduced}
              table={table}
            />
          )}

          <tbody
            className={classnames('relative flex h-fit flex-col overflow-auto', {
              'max-h-[calc(100dvh-400px)]': isBodyHeightLimited,
            })}
          >
            {renderBody()}
          </tbody>
        </table>
      </div>

      {isPaginated && data?.length > 0 && (
        <Pagination
          {...{ customPageSize, perPageCount, table }}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />
      )}
    </Fade>
  );
};
