import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { EquipmentMobileList } from '@/modules/Equipment/components/common/EquipmentMobileList';
import { EquipmentSummary } from '@/modules/Equipment/components/common/EquipmentSummary';
import { EquipmentsAccordsBody } from '@/modules/Equipment/components/tabs/Equipment/EquipmentsAccordsBody.tsx';
import { useFilteredEquipmentData } from '@/modules/Equipment/hooks/useFilteredEquipmentData.ts';
import { useSearchEquipment } from '@/modules/Equipment/hooks/useSearchEquipment.ts';
import { renderColumns } from '@/modules/Equipment/utils/Equipments.utils.tsx';
import { useAssetHooks } from '@/shared/api/assets/assets.hooks.ts';
import { AssetHighlightAlert } from '@/shared/components/navigation/AssetHighlightAlert';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { AssetControlPanel } from '@/shared/components/ui/AssetControlPanel';
import { StatisticCard } from '@/shared/components/ui/Card';
import { TopSection } from '@/shared/components/ui/TopSection';
import { useAssetStatus } from '@/shared/hooks/assets/useAssetStatus.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { useGlobalStore } from '@/shared/store/store.ts';
import { URL_PARAMS } from '@/shared/utils/constants.ts';
import { RolesWrapper } from '@/shared/wrappers/Roles.tsx';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const Equipments = () => {
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();

  const setMapFilter = useGlobalStore(store => store.setMapFilter);

  const assetResourceName = searchParameters.get(URL_PARAMS.ASSET_RES_NAME);
  const equipmentSubtype = searchParameters.get(URL_PARAMS.EQUIPMENT_SUBTYPE);
  const equipmentStatus = searchParameters.get(URL_PARAMS.EQUIPMENT_STATUS);
  const equipmentZone = searchParameters.get(URL_PARAMS.ZONE);
  const equipmentType = searchParameters.get(URL_PARAMS.EQUIPMENT_TYPE);
  const equipmentTag = searchParameters.get(URL_PARAMS.TAG_ATTACHED);
  const equipmentAvailability = searchParameters.get(URL_PARAMS.ASSET_AVAILABILITY);

  const { isFetching: isEquipmentLoading, refetch } = useAssetHooks.getAllEquipments();

  const {
    allowToggleEquipment,
    isMutatingStatus,
    isShowInactive,
    setIsShowInActive,
    toggleShowInactive,
  } = useAssetStatus();

  const { renderItemVisibility } = useRole();

  const { filteredActiveAllAssets, filteredAssets } = useFilteredEquipmentData(assetResourceName);

  const { filteredEquipments } = useSearchEquipment({
    equipmentAvailability,
    equipments: filteredAssets,
    equipmentStatus,
    equipmentSubtype,
    equipmentTag,
    equipmentType,
    equipmentZone,
    isShowInactive,
  });

  const handleShowEquipmentsOnMap = () => {
    const uuid =
      filteredEquipments
        ?.filter(equipment => equipment.assetStatus === 'active')
        ?.map(equipment => equipment.assetResName) ?? [];
    setMapFilter({ resName: uuid });
    navigate({ pathname: RoutesEnum.HOME });
  };

  useEffect(() => {
    if (!allowToggleEquipment && isShowInactive) {
      toggleShowInactive();
    }
  }, [allowToggleEquipment]);

  useEffect(() => {
    return () => {
      setIsShowInActive(false);
    };
  }, []);

  return (
    <div className="flex flex-col gap-y-4">
      {assetResourceName && <AssetHighlightAlert to={RoutesEnum.EQUIPMENT} />}

      <div className="flex flex-wrap items-center justify-between gap-2">
        <TopSection scope={['subtype', 'availability', 'equipment-status', 'attached']} />

        <AssetControlPanel
          checked={isShowInactive}
          disabled={isMutatingStatus || isEquipmentLoading}
          onChange={toggleShowInactive}
          onRefresh={refetch}
          onShowOnMap={handleShowEquipmentsOnMap}
          scopes={allowToggleEquipment ? ['toggle', 'refresh'] : ['refresh']}
        />
      </div>

      <RolesWrapper action="show_total_equipment_and_equipment_in_use_stats">
        <div className="inline-flex w-full flex-col items-center gap-x-3 gap-y-2 md:flex-row">
          <StatisticCard
            icon="equipment"
            isLoading={isMutatingStatus || isEquipmentLoading}
            subtitle={filteredEquipments.length ?? 0}
            titleId="global.totalEquipment"
          />

          <StatisticCard
            icon="equipment"
            isLoading={isMutatingStatus || isEquipmentLoading}
            subtitle={filteredActiveAllAssets.length ?? 0}
            titleId="global.equipmentInUse"
          />
        </div>
      </RolesWrapper>

      <div className="grid gap-y-3 md:hidden">
        <EquipmentSummary
          inUse={filteredActiveAllAssets.length}
          isLoading={isMutatingStatus || isEquipmentLoading}
          total={filteredAssets.length}
        />

        <EquipmentMobileList
          equipments={filteredEquipments}
          isLoading={isMutatingStatus || isEquipmentLoading}
        />
      </div>

      <div className="hidden gap-y-5 md:grid">
        <DefaultTable
          accordedChildren={items => <EquipmentsAccordsBody items={items} />} //NOSONAR
          className="hidden max-h-[calc(100dvh-420px)] md:block"
          columns={renderColumns(renderItemVisibility)}
          contentRowType="equipment"
          data={filteredEquipments}
          isLoading={isMutatingStatus || isEquipmentLoading}
          withToggle={isShowInactive}
        />
      </div>
    </div>
  );
};
