import { FC, useMemo, useState } from 'react';

import { translate } from '@/i18n';
import { Pagination } from '@/shared/components/navigation/Pagination';
import { ActionCard } from '@/shared/components/ui/ActionCard';
import { NoData } from '@/shared/components/ui/NoData';
import { Typography } from '@/shared/components/ui/Typography';
import { useActions } from '@/shared/hooks/actions/useActions.ts';
import { classnames } from '@/shared/utils/classnames.ts';

import { WithClassName } from '@/shared/types/global/common.types.ts';

export const MostImportantList: FC<WithClassName> = ({ className }) => {
  const { activeAlerts, hasPriority, totalCount } = useActions();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(100);

  const paginatedAlerts = useMemo(() => {
    const fullList = [...activeAlerts];
    fullList.sort((a, b) => b.timestamp - a.timestamp);
    return fullList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [activeAlerts, currentPage, pageSize]);

  if (!hasPriority) return <></>;

  if (totalCount <= 0) return <NoData title={translate('actions.noActionsFound')} />;

  return (
    <div className={classnames('flex w-full flex-col gap-y-1.5', className)}>
      <Typography as="h2" className="text-lg font-medium">
        {translate('actions.mostImportant')}
      </Typography>

      <div aria-details="most important alerts" className="flex w-full flex-col gap-3">
        {paginatedAlerts?.map(action => (
          <ActionCard action={action} buttonPlacement="row" key={action.alertResName} />
        ))}
      </div>

      <Pagination
        className="justify-center"
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        pageSize={pageSize}
        totalCount={totalCount}
      />
    </div>
  );
};
