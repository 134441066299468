import { FC, PropsWithChildren } from 'react';
import { Controller } from 'react-hook-form';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { FilterSelect } from '@/shared/components/form/FilterSelect';
import { useMapFilter } from '@/shared/components/ui/Modal/MapFilter/hooks/useMapFilter.ts';
import { Typography } from '@/shared/components/ui/Typography';

import { MapFilterPropEnum } from '@/shared/types/global/filters.types.ts';

export const MapFilter: FC = () => {
  const { closeModal, filtersSelected, form, handleFormReset, handleFormSubmit } = useMapFilter();

  return (
    <form className="grid w-full max-w-md gap-y-3 pb-16" onSubmit={handleFormSubmit}>
      <Typography as="strong" className="text-xl tracking-[0.1px]">
        {translate('filters.title')}
      </Typography>

      {/* PEOPLE */}
      <FilterInputWrapper>
        <FilterLabel>{translate('filters.people')}</FilterLabel>

        {[MapFilterPropEnum.FIRM, MapFilterPropEnum.WORKER_ROLE].map(filterProp => (
          <Controller
            control={form.control}
            key={filterProp}
            name={filterProp}
            render={({ field: { onChange, value } }) => (
              <FilterSelect name={filterProp} onChange={onChange} value={value} />
            )}
          />
        ))}
      </FilterInputWrapper>

      {/* EQUIPMENT */}
      <FilterInputWrapper>
        <FilterLabel>{translate('filters.equipment')}</FilterLabel>

        {[MapFilterPropEnum.EQUIPMENT_TYPE, MapFilterPropEnum.EQUIPMENT_SUB_TYPE].map(
          filterProp => (
            <Controller
              control={form.control}
              key={filterProp}
              name={filterProp}
              render={({ field: { onChange, value } }) => (
                <FilterSelect name={filterProp} onChange={onChange} value={value} />
              )}
            />
          ),
        )}
      </FilterInputWrapper>

      {/* ZONE */}
      <FilterInputWrapper>
        <FilterLabel>{translate('filters.zone')}</FilterLabel>

        {[MapFilterPropEnum.ZONE_TYPE, MapFilterPropEnum.ZONE_FLOOR].map(filterProp => (
          <Controller
            control={form.control}
            key={filterProp}
            name={filterProp}
            render={({ field: { onChange, value } }) => (
              <FilterSelect name={filterProp} onChange={onChange} value={value} />
            )}
          />
        ))}
      </FilterInputWrapper>

      {/*BUTTON GROUP*/}
      <div className="absolute bottom-0 left-0 z-20 flex h-[70px] w-full items-center justify-end gap-x-2 rounded-b-2xl border-t border-slate-200 bg-slate-100 px-3 py-2">
        <Button
          className="mr-auto border-slate-200 bg-white text-red-600 hover:bg-red-50 focus:bg-red-50"
          disabled={!filtersSelected}
          onClick={handleFormReset}
          type="reset"
          variant="outline"
        >
          {translate('filters.cancelAll')}
        </Button>

        <Button
          className="border-slate-200 bg-white text-slate-800 hover:bg-slate-50 focus:bg-slate-50"
          onClick={closeModal}
          variant="outline"
        >
          {translate('filters.cancel')}
        </Button>

        <Button disabled={!filtersSelected} type="submit" variant="solid">
          {translate('filters.confirm')}
        </Button>
      </div>
    </form>
  );
};

const FilterLabel: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography as="span" className="text-sm font-medium text-slate-950 first-letter:capitalize">
      {children}
    </Typography>
  );
};

const FilterInputWrapper: FC<PropsWithChildren> = ({ children }) => {
  return <div className="grid gap-y-2 text-left">{children}</div>;
};
