import type { ReactNode } from 'react';

import { flexRender, Row } from '@tanstack/react-table';

import { Toggle } from '@/shared/components/form/Toggle';
import { TableData } from '@/shared/components/tables/TableData';
import { TableRow } from '@/shared/components/tables/TableRow';
import { useToggleAssetsStatus } from '@/shared/hooks/assets/useToggleAssetsStatus.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { classnames } from '@/shared/utils/classnames.ts';
import { hasRequiredFields } from '@/shared/utils/table.ts';

import { UseTableBodyProps } from '@/shared/components/tables/DefaultTable/DefaultTable.types.ts';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';

export function useTableEquipmentBody<T>({
  contentRowType,
  extendedRow,
  isFirstRowReduced,
  rowClassName,
  table,
  withToggle,
}: UseTableBodyProps<T>) {
  const { isPending, mutate } = useToggleAssetsStatus();

  const handleMutate = (data: AssetBody) => {
    if (hasRequiredFields(data)) {
      notifyService.error('The asset is missing some required fields.');
      return;
    }

    mutate(data);
  };

  const items = <K extends Record<string, string>>(
    data?: K,
    customRow?: (row: Row<T>) => ReactNode,
  ) => {
    const tableRows = table.getRowModel().rows;

    const filteredTableRows = data
      ? tableRows.filter(
          row => (row.original as AssetBody).categoryPathResName === data.categoryPathResName,
        )
      : tableRows;

    return filteredTableRows.map(row => {
      const isDeactivated = Object.values(row.original as Record<string, string>).includes(
        'deactivated',
      );

      if (customRow) return customRow(row);

      if (contentRowType === 'equipment')
        return (
          <TableRow
            className={classnames(isDeactivated && 'bg-slate-50', !data && 'pl-4', rowClassName)}
            data-index={row.index}
            key={row.id}
            ref={node => node}
          >
            {row.getVisibleCells().map((cell, index) => {
              const data = cell.row?.original as AssetBody;
              const isActive = data?.assetStatus === 'active';
              const isInactiveRow = !isActive && withToggle;

              return (
                <TableData
                  className={classnames(
                    'pl-[22px]',
                    extendedRow && 'h-[64px]',
                    !cell.column.columnDef.header && 'min-w-[120px] max-w-[120px]',
                    isInactiveRow && 'opacity-30',
                    isFirstRowReduced && 'first:min-w-[242px] first:max-w-[242px]',
                  )}
                  data-index={row.index}
                  key={cell.id}
                >
                  {index === 0 && withToggle && (
                    <Toggle
                      checked={isActive}
                      color="green"
                      disabled={isPending}
                      onChange={() => handleMutate(data)} //NOSONAR
                    />
                  )}

                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableData>
              );
            })}
          </TableRow>
        );
    });
  };

  return { items };
}
