import { useMedia } from 'react-use';

import { NotFound } from '@/modules/Reports/components/NotFound';
import { UtilizationAccordsBody } from '@/modules/Reports/components/TabPanels/Utilization/UtilizationAccordsBody.tsx';
import { useUtilization } from '@/modules/Reports/hooks/useUtilization.ts';
import { renderColumns } from '@/modules/Reports/utils/Utilization.utils.tsx';
import { DefaultTable } from '@/shared/components/tables/DefaultTable';
import { StatisticCard } from '@/shared/components/ui/Card';
import { TopSection } from '@/shared/components/ui/TopSection';
import { classnames } from '@/shared/utils/classnames.ts';

type UtilizationProps = { isLoading?: boolean };

export const Utilization = ({ isLoading }: UtilizationProps) => {
  const {
    filteredModifiedUtilization,
    isShowInactive,
    isUtilizationLoading,
    totalCalculatedTime,
    utilization,
  } = useUtilization();
  const isMobile = useMedia('(max-width: 767px)');

  return (
    <div className="flex flex-col gap-y-4">
      <div className="inline-flex w-full flex-wrap items-center justify-between gap-x-3">
        <TopSection
          assetsInitialType="equipment"
          disableFromTo
          isSearchBarHidden
          scope={['multi-date', 'multi-asset', 'zone', 'subtype']}
          withMaxDate
        />
      </div>

      {!isUtilizationLoading && utilization.length <= 0 ? (
        <NotFound />
      ) : (
        <>
          <div
            className={classnames('inline-flex w-full items-center gap-x-3', {
              'flex-col gap-y-2': isMobile,
            })}
          >
            <StatisticCard
              icon="equipment"
              isLoading={isLoading || isUtilizationLoading}
              subtitle={String(utilization.length) || '0'}
              titleId="reports.equipmentSelected"
            />

            <StatisticCard
              icon="clock"
              isLoading={isLoading || isUtilizationLoading}
              subtitle={totalCalculatedTime}
              titleId="reports.totalTimeInUse"
            />

            <StatisticCard
              icon="calendar"
              isLoading={isLoading || isUtilizationLoading}
              subtitle={String(filteredModifiedUtilization.length) || '0'}
              titleId="reports.nonOperationalDays"
            />
          </div>

          <DefaultTable
            accordedChildren={(
              items, //NOSONAR
            ) => <UtilizationAccordsBody items={items} utilization={utilization} />} //NOSONAR
            className="max-h-[calc(100vh-420px)]"
            columns={renderColumns({ isShowInactive })}
            contentRowType="chart"
            data={utilization}
            debugTable
            isChart={true}
            isLoading={isLoading || isUtilizationLoading}
          />
        </>
      )}
    </div>
  );
};
