import type { ReactNode } from 'react';

import { EquipmentSummary } from '@/modules/Equipment/components/common/EquipmentSummary';
import { hideInactiveAssets } from '@/modules/Subcontractors/utils/helpers';
import { useAssetStatus } from '@/shared/hooks/assets/useAssetStatus';
import {
  CategorySubTypes,
  CategoryTypes,
  useTableAccordions,
} from '@/shared/hooks/table/useTableAccordions.tsx';
import { useFilteredSocketData } from '@/shared/hooks/ws/useFilteredSocketData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';

type Props<K> = {
  items: (data: K) => ReactNode;
};

export const getTagValue = (asset: AssetBody) => {
  const property = asset.extraProperties?.find(property => property.key === 'tag');

  return (property?.value as string) ?? '';
};

export const EquipmentsAccordsBody = <T extends Record<string, string>, K>({ items }: Props<K>) => {
  const { assets } = useGlobalStore(({ assets }) => ({ assets }));

  const { isShowInactive } = useAssetStatus();

  const { filteredSocketMapData } = useFilteredSocketData();
  const { currentCategories, handleToggleAccordion } = useTableAccordions('equipment');

  const renderCustomMeta = <T extends Record<string, string>>(args: T, flow: 'subs' | 'types') => {
    const filteredAssets = hideInactiveAssets(assets, isShowInactive).filter(asset =>
      flow === 'types'
        ? asset.categoryTreeResName === args.categoryTreeResName
        : asset.categoryPathResName === args.categoryPathResName,
    );

    const filteredActiveAssets = filteredAssets.filter(asset => {
      const propertyValue = getTagValue(asset);

      const currentAsset = filteredSocketMapData.find(item => item.tagResName === propertyValue);

      return !!currentAsset;
    });

    return <EquipmentSummary inUse={filteredActiveAssets.length} total={filteredAssets.length} />;
  };

  return (
    <CategoryTypes
      currentCategories={currentCategories}
      handleToggleAccordion={handleToggleAccordion}
      meta={(category: T) => renderCustomMeta(category, 'types')}
    >
      {category => (
        <CategorySubTypes
          category={category}
          handleToggleAccordion={handleToggleAccordion}
          items={(subCategory: K) => items(subCategory)}
          meta={(pathList: T) => renderCustomMeta(pathList, 'subs')}
        />
      )}
    </CategoryTypes>
  );
};
