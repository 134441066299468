import { FC } from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';

import { translate } from '@/i18n';
import { Badge } from '@/shared/components/ui/Badge';
import { Loader } from '@/shared/components/ui/Loader';
import { useActions } from '@/shared/hooks/actions/useActions.ts';
import { useClearStateData } from '@/shared/hooks/global/useClearStateData.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { classnames } from '@/shared/utils/classnames';

import { icons, NavLinkProps } from '@/shared/components/navigation/Navbar/Navbar.types';
import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const NavLink: FC<NavLinkProps> = ({
  disabled,
  hasNewEvent,
  icon,
  name,
  onClick,
  path,
  withCounter,
}) => {
  const location = useLocation();

  const setPreviousTab = useGlobalStore(state => state.setPreviousTab);

  const { clearLocationHistory, setSidebarFilterValue } = useClearStateData();
  const { badgeCount, isLoading: isActionsLoading } = useActions();

  const Icon = icons[icon];

  return (
    <Link
      aria-label={`navbar-${name}-action`.toLowerCase()}
      className={({ isActive }) =>
        classnames(
          'group relative inline-flex w-full items-center gap-x-1 rounded-lg px-3 py-2 text-base font-normal tracking-wider text-white transition-colors duration-300 hover:bg-blue-500 focus:border-none md:flex-col md:px-1 md:py-0 md:text-[12px] md:text-sm md:focus:bg-blue-500 lg:flex-row lg:px-3 lg:py-2 lg:text-sm lg:font-medium xl:px-2',
          isActive ? 'pointer-events-none bg-blue-500' : 'bg-transparent',
          disabled && 'pointer-events-none select-none opacity-50',
          hasNewEvent && 'animate-pulse duration-1000',
        )
      }
      data-testid="nav-link"
      onClick={() => {
        setPreviousTab(location.pathname as RoutesEnum);
        setSidebarFilterValue('');
        clearLocationHistory();
        onClick && onClick();
      }}
      to={path}
    >
      <Icon aria-hidden="true" className="navLinkAnimation size-6" />

      {translate(name)}

      {withCounter && (
        <Badge
          className="counterAnimation ml-auto inline-flex min-h-4 min-w-6 items-center justify-center md:absolute md:-right-4 md:-top-1.5 md:ml-0"
          color="red"
          variant="solid"
        >
          {isActionsLoading ? <Loader appearance="ping" /> : badgeCount}
        </Badge>
      )}
    </Link>
  );
};
